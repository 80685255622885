import { Button, Card, CardFooter, Typography } from "@material-tailwind/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PlusIcon,
  TrashIcon,
  PencilIcon,
  MagnifyingGlassIcon
} from '@heroicons/react/24/outline'
import { PessoaContext } from "../../../../contexts/Pessoa/PessoaContext";
import { Tooltip } from "@mui/material";
import { AuthContext } from "../../../../contexts/Auth/AuthContext";
import DetailModal from "../../../../components/Utils/DetailModal";

const TABLE_HEAD = ["Administrativo", "Email","Telefone","Ação"];

const ITEMS_PER_PAGE = 20;

interface SortConfig {
  key: keyof AdministrativoItem;
  direction: 'ascending' | 'descending';
}

interface AdministrativoItem {
  id_hash: string;
  id_administrativo: string;
  nome_administrativo: string;
  cpf: string;
  dt_inicio: string;
  dt_fim: string;
  email: string;
  telefone: string;
  permissao: number; //
}

interface DeleteItemParams {
  id_hash: string;
  id_administrativo: string;
  nome_administrativo: string;
}

export function GerenciaAdministrativo() {
  const { administrativoActions } = useContext(PessoaContext);
  const [tableData, setTableData] = useState<AdministrativoItem[]>([]);
  const [nomeFilter, setNomeFilter] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<DeleteItemParams | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [, setSelectedItem] = useState<AdministrativoItem | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<{ [key: string]: any }>({});
  const auth = useContext(AuthContext);
  const idUser = auth.user?.id_user;
  const IsAdministrador = !!(auth.user?.ocupacao === 'Administrador' || auth.user?.admin);
  const IsAdministradorSupervisor = !!(auth.user?.ocupacao === 'Administrador' || auth.user?.admin || auth.user?.supervisor);
  const IsTerapeuta = !!(auth.user?.ocupacao === 'Terapeuta');
  const IsMonitor = !!(auth.user?.ocupacao === 'Monitor');
  const IsAdministrativo = !!(auth.user?.ocupacao === 'Administrativo');
  const AdministrativoPermissaoPessoa = (auth.user?.permissao ?? 0) >= 2;
  const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 'id_administrativo', direction: 'ascending' });

  const handleSort = (key: keyof AdministrativoItem) => {
    let direction: 'ascending' | 'descending';

  
  if (sortConfig.key === key) {
    // Alterna a direção se a mesma coluna for clicada
    direction = sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
  } else {
    // Define a direção como 'ascending' para novas colunas
    direction = 'ascending';
  }

  setSortConfig({ key, direction });
};


  useEffect(() => {
    const fetchItemData = async () => {
      setIsLoading(true);
      let ItemData: AdministrativoItem[];
      try {
        ItemData = await administrativoActions.fill();
        setTableData(ItemData);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchItemData();
  }, [administrativoActions]);

  useEffect(() => {
    if (nomeFilter !== '') {
      setCurrentPage(1);
    }
  }, [nomeFilter]);

    const removeAccents = (str: string) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

  
const filteredData = Array.isArray(tableData) ? tableData.filter(({ nome_administrativo }) => {
  if (nome_administrativo) {
    const normalizedNome = removeAccents(nome_administrativo.toString().toLowerCase());
    const normalizedFilter = removeAccents(nomeFilter.toLowerCase());
    const nomeMatches = nomeFilter ? normalizedNome.includes(normalizedFilter) : true;
    return nomeMatches;
  }
  return true;
}) : [];

const fieldMap: { [key: string]: keyof AdministrativoItem } = {
   
  "administrativo": "nome_administrativo",  
};
  
const sortedData = [...filteredData].sort((a, b) => {
  const keyA = fieldMap[sortConfig.key] || sortConfig.key;
  const aKey = a[keyA];
  const bKey = b[keyA];
  
  if (typeof aKey === 'string' && typeof bKey === 'string') {
    return sortConfig.direction === 'ascending' 
      ? aKey.localeCompare(bKey) 
      : bKey.localeCompare(aKey);
  }

  // Adiciona uma verificação para valores numéricos
  if (typeof aKey === 'number' && typeof bKey === 'number') {
    return sortConfig.direction === 'ascending' ? aKey - bKey : bKey - aKey;
  }

  return 0;
});

  
  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);

  const visibleData = sortedData.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);


  const handleDeleteButtonClick = (deleteItem: DeleteItemParams) => {
      setDeleteItem(deleteItem);
      setConfirmDelete(true);
  };

  const handleButtonClick = () => {
    navigate('/administrativo/add');
  };

  const handleEditButtonClick = (item: AdministrativoItem) => {
    setSelectedItem(item);
    navigate('/administrativo/edit', { state: { selectedItem: item } });
  };

  const first = () => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }
  };

  const ult = () => {
    if (currentPage < totalPages) {
      setCurrentPage(totalPages);
    }
  };

  const next = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const confirmDeleteItem = () => {
    if (deleteItem && idUser  && IsAdministrador && IsAdministrador === true) {

      administrativoActions.delete(String(deleteItem.id_administrativo), idUser, String(deleteItem.id_hash))
      .then(() => {

          setDeleteItem(null);
          setConfirmDelete(false);
          window.location.reload();
        })
        .catch((error) => {
          console.error('Erro ao deletar o item:', error);
        });
    } else {
      alert('Você não possui permissão, caso necessite realizar a operação entre em contato com o administrador para alterar as permissões.');
          }
  };

    const cancelDeleteItem = () => {
      setDeleteItem(null);
      setConfirmDelete(false);
    };

   const handleViewButtonClick = (item: AdministrativoItem) => {

    setModalData({
      "Nome": item.nome_administrativo?item.nome_administrativo:'',
      "Email": item.email?item.email:'',
      "CPF": item.cpf?item.cpf:'',
      "Data de Início": item.dt_inicio?new Date(item.dt_inicio).toLocaleDateString('pt-br', { timeZone: 'UTC' }):'',
      "Data de Fim": item.dt_fim?new Date(item.dt_fim).toLocaleDateString('pt-br', { timeZone: 'UTC' }):''
    });
    setIsModalOpen(true);
  };
  
  return (
    <div>
      <div className="p-4 mb-4 flex flex-col md:flex-row md:items-center md:space-x-4 bg-gray-100 rounded-lg">
        <Button 
          placeholder='' 
          variant="outlined" 
          size="sm"
          onClick={handleButtonClick}
          className={'p-2 my-2 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-normal rounded-lg text-sm me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'}
          >
          <PlusIcon className='w-6 h-6' />
        </Button>
        <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-2 my-4">
          <label htmlFor="administrativoFilter" className="text-black-700 md:mt-0 mt-2">
            Administrativo
          </label>
          <div className="relative">
            <input
              type="text"
              id="administrativoFilter"
              value={nomeFilter}
              onChange={(e) => setNomeFilter(e.target.value)}
              className="border border-gray-300 pl-8 pr-2 py-2 rounded"
            />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap md:flex-nowrap items-center pl-24">
          <h2 className="text-3xl font-bold font-mono relative pr-4 italic text-transparent bg-clip-text bg-gradient-to-r to-green-600 from-blue-400 mb-4 md:mb-0">
            Registros
          </h2>
          <h2 className="text-3xl font-bold font-mono relative pr-4 italic text-transparent bg-clip-text bg-gradient-to-r to-blue-600 from-green-400 mb-4 md:mb-0">
            Administrativo
          </h2>
        </div>
      </div>
      <Card placeholder='' className="h-full w-full overflow-auto">
        <table className="w-full min-w-max table-auto text-center">
          <thead>
          <tr>
            {TABLE_HEAD.map((head) => {
              const key = head.toLowerCase().replace(/\s+/g, '') as keyof AdministrativoItem;
              return (
                <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 cursor-pointer" onClick={() => handleSort(key)}>
                  <Typography placeholder='' variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                    {head} {sortConfig.key === key ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                  </Typography>
                </th>
              );
            })}
          </tr>
        </thead>
               <tbody>
          {isLoading ? (
              <tr>
                <td colSpan={TABLE_HEAD.length} className="p-4">Carregando...</td>
              </tr>
            ) : visibleData.map((item, index) => {
            // Verifica se o id_administrativo é diferente do idUser
            if (item.id_administrativo !== idUser) {
              return (
                <tr key={index} className={index % 2 === 0 ? "bg-gray-200" : "bg-white"}>
                  <td className="p-4 border-b border-blue-gray-100">
                    <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
                      {item.nome_administrativo ? item.nome_administrativo : ''}
                    </Typography>
                  </td>
                  <td className="p-4 border-b border-blue-gray-100">
                    <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
                      {item.email ? item.email : ''}
                    </Typography>
                  </td>
                  <td className="p-4 border-b border-blue-gray-100">
                    <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
                      {item.telefone ? item.telefone : ''}
                    </Typography>
                  </td>
              <td className="border-b border-blue-gray-100 p-2">
                <div className="flex space-x-2 justify-center">
                  <Tooltip title="Visualizar">
                    <Button 
                      placeholder=''
                      className="w-8 h-8 flex items-center justify-center p-1 bg-blue-500 hover:bg-blue-700 text-white rounded-full"
                      onClick={() => handleViewButtonClick(item)}
                    >
                      <MagnifyingGlassIcon className="h-5 w-5" />
                    </Button>
                  </Tooltip>
                  { AdministrativoPermissaoPessoa === true || IsAdministradorSupervisor?<Tooltip title="Atualizar Informações" placement="top">
                    <Typography
                      placeholder=''
                      variant="small"
                      as="button"
                      onClick={() => handleEditButtonClick(item)}
                      color="blue-gray"
                      className="w-8 h-8 flex items-center justify-center p-1 bg-green-500 hover:bg-green-700 text-white rounded-full"
                      >
                      <PencilIcon className="w-6 h-6" />
                    </Typography>
                  </Tooltip>:''}
                  {IsAdministrador === true?<Tooltip title="Deletar" placement="top">
                    <Button
                      placeholder=""
                      color="blue-gray"
                      className="w-8 h-8 flex items-center justify-center p-1 bg-red-500 hover:bg-red-700 text-white rounded-full"
                      onClick={() => handleDeleteButtonClick(item)}
                    >
                      <TrashIcon className="w-6 h-6" />
                    </Button>
                  </Tooltip>:''} 
                </div>
              </td>
                </tr>
              );
            }
            // Se o id_administrativo for igual ao idUser, não renderize esse item na tabela
            return null;
          })}

          </tbody>
      {confirmDelete && deleteItem && (
        <div className="fixed inset-0 z-10 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-3xl mx-auto my-6">
            <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
              <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blue-gray-200">
                <h3 className="text-3xl font-semibold">Confirmar exclusão</h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={cancelDeleteItem}
                >
                  <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                </button>
              </div>
              <div className="relative p-6 flex-auto">
                <p className="my-4 text-blue-gray-500 text-lg leading-relaxed">Tem certeza que deseja excluir o Administrativo ID: {deleteItem.id_administrativo}?</p>
              </div>
              <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blue-gray-200">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={cancelDeleteItem}
                >
                  Cancelar
                </button>
                <button
                  className="bg-red-700 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={confirmDeleteItem}
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
        </table>
      </Card>
      <CardFooter placeholder='' className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
        Página <strong className="text-blue-gray-900">{currentPage}</strong> de{" "}
              <strong className="text-blue-gray-900">{totalPages}</strong>
        </Typography>
        <div className="flex gap-2">
        <Button 
          placeholder='' 
          variant="outlined" 
          size="sm"
          onClick={first}
          disabled={currentPage === 1}
          className="p-2"
          >
            Primeira
          </Button>
          <Button 
          placeholder='' 
          variant="outlined" 
          size="sm"
          onClick={prev}
          disabled={currentPage === 1}
          className="p-2"
          >
            Anterior
          </Button>
          <Button 
          placeholder='' 
          variant="outlined" 
          size="sm" 
          onClick={next}
          disabled={currentPage === totalPages}
          className="p-2"
          >
            Próximo
          </Button>
          <Button 
          placeholder='' 
          variant="outlined" 
          size="sm"
          onClick={ult}
          disabled={currentPage === totalPages}
          className="p-2"
          >
            Última
          </Button>
        </div>
      </CardFooter>
      <DetailModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        data={modalData}
      />
    </div>
  );
}
