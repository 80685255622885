import React, { useState, useEffect } from "react";
import { AgendaRecepcaoManha } from "./Recepacao_Manha";
import { AgendaRecepcaoTarde } from "./Recepacao_Tarde";
import Legend from "../../components/Utils/Legend";

const AgendaRecepcao: React.FC = () => {
  const [selectedAgenda, setSelectedAgenda] = useState<"manha" | "tarde">(
    () => {
      // Recupera o valor do localStorage ou usa 'manha' como padrão
      return (
        (localStorage.getItem("selectedAgenda") as "manha" | "tarde") || "manha"
      );
    }
  );

  // Atualiza o localStorage sempre que selectedAgenda mudar
  useEffect(() => {
    localStorage.setItem("selectedAgenda", selectedAgenda);
  }, [selectedAgenda]);

  // Use `useEffect` para restaurar a posição de rolagem quando o componente é montado
  useEffect(() => {
    const scrollX = sessionStorage.getItem("scrollX");
    const scrollY = sessionStorage.getItem("scrollY");

    if (scrollX !== null && scrollY !== null) {
      window.scrollTo(parseInt(scrollX, 10), parseInt(scrollY, 10));
      sessionStorage.removeItem("scrollX");
      sessionStorage.removeItem("scrollY");
    }
  }, []); // O array vazio garante que este código só seja executado na montagem inicial

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      {/* Área de seleção Manhã/Tarde */}
      <nav className="flex justify-center items-center space-x-2 p-3 bg-gray-200">
        <button
          onClick={() => setSelectedAgenda("manha")}
          className={`px-4 py-2 rounded ${
            selectedAgenda === "manha"
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-gray-700"
          }`}
        >
          Manhã
        </button>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => setSelectedAgenda("tarde")}
            className={`px-4 py-2 rounded ${
              selectedAgenda === "tarde"
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            Tarde
          </button>
          <Legend /> {/* Adicione o componente Legend ao lado do botão */}
        </div>
      </nav>

      {/* Área da agenda */}
      <div className="flex-1 bg-white p-3 rounded shadow-md">
        {selectedAgenda === "manha" && <AgendaRecepcaoManha />}
        {selectedAgenda === "tarde" && <AgendaRecepcaoTarde />}
      </div>
    </div>
  );
};

export default AgendaRecepcao;
