// src/permissions/Permissions.tsx

import { AuthContext } from './AuthContext';
import { useContext } from 'react';

export type UserPermissions = {
  IsAdministradorSupervisor: boolean;
  IsTerapeuta: boolean;
  IsMonitor: boolean;
  IsAdministrativo: number;
};

export const useUserPermissions = (): UserPermissions => {
  const auth = useContext(AuthContext);

  return {
    IsAdministradorSupervisor: !!(auth.user?.ocupacao === 'Administrador' || auth.user?.admin || auth.user?.supervisor),
    IsTerapeuta: !!(auth.user?.ocupacao === 'Terapeuta'),
    IsMonitor: !!(auth.user?.ocupacao === 'Monitor'),
    IsAdministrativo: auth.user?.permissao || 0,
  };
};

export const Permissions = {
  administradorSupervisor: {
    gerenciaPessoas: true,
    gerenciaGeral: true,
    agendamentos: true,
    agendaRecepcao: true,
  },
  terapeuta: {
    gerenciaPessoas: true,
    gerenciaGeral: false,
    agendamentos: true,
    agendaRecepcao: false,
  },
  monitor: {
    gerenciaPessoas: false,
    gerenciaGeral: false,
    agendamentos: false,
    agendaRecepcao: false,
  },
  administrativo: (level: number) => ({
    gerenciaPessoas: level >= 2,
    gerenciaGeral: level >= 3,
    agendamentos: level >= 2,
    agendaRecepcao: level >= 1,
  }),
};

// Função para verificar permissões
export const hasPermission = (
  permissions: UserPermissions,
  permission: keyof typeof Permissions['administradorSupervisor']
) => {
  if (permissions.IsAdministradorSupervisor) {
    return Permissions.administradorSupervisor[permission];
  }
  if (permissions.IsTerapeuta) {
    return Permissions.terapeuta[permission];
  }
  if (permissions.IsMonitor) {
    return Permissions.monitor[permission];
  }
  return Permissions.administrativo(permissions.IsAdministrativo)[permission];
};
