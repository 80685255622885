import { useContext, useState } from "react";
import { PessoaContext } from "../../../../contexts/Pessoa/PessoaContext";
import { Link } from "react-router-dom";
import MessageBox from "../../../../components/Utils/MessageBox";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { AuthContext } from "../../../../contexts/Auth/AuthContext";

export const AdicionaAdministrativo = () => {
  const { administrativoActions } = useContext(PessoaContext);
  const [nome, setNome] = useState<string | undefined>('');
  const [cpf, setCpf] = useState<string | undefined>('');
  const [dtInicio, setDtInicio] = useState<string>('');
  const [dtFim, setDtFim] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [telefone, setTelefone] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [showIcon, setShowIcon] = useState<boolean>(false); 
  const [cpfError, setCpfError] = useState<boolean>(false);
  const auth = useContext(AuthContext);
  const idUser = auth.user?.id_user;
  const IsAdministrador = !!(auth.user?.ocupacao === 'Administrador' || auth.user?.admin);
  const IsAdministradorSupervisor = !!(auth.user?.ocupacao === 'Administrador' || auth.user?.admin || auth.user?.supervisor);
  const IsTerapeuta = !!(auth.user?.ocupacao === 'Terapeuta');
  const IsMonitor = !!(auth.user?.ocupacao === 'Monitor');
  const IsAdministrativo = !!(auth.user?.ocupacao === 'Administrativo');
  const [nivelPermissao, setNivelPermissao] = useState<number>(1);

  const handlePermissaoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNivelPermissao(Number(e.target.value));
  };

  const handleSubmit = async () => {
    if (nome && email && idUser) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const cpfString = cpf || '';

      if (!validarCPF(cpfString)) {
        setMessage("CPF inválido");
        setShowIcon(true);
        setShowMessage(true);
        setCpfError(true);
        return;
      } else {
        setCpfError(false);
      }

      if (!emailPattern.test(email)) {
        setMessage("Por favor, insira um e-mail válido.");
        setShowIcon(true);
        setShowMessage(true);
        return;
      }

      const phonePattern = /^\(?\d{2}\)?\s?\d{4,5}-?\d{4}$/;
      if (!phonePattern.test(telefone)) {
        setMessage("Por favor, insira um número de telefone válido.");
        setShowIcon(true);
        setShowMessage(true);
        return;
      }

      const idUserAltered = await administrativoActions.add({
        id_administrativo: null,
        nome: nome,
        cpf: cpf ?? '',
        dt_inicio: dtInicio,
        dt_fim: dtFim,
        email: email,
        telefone: telefone,
        id_user: idUser,
        permissao: nivelPermissao,
      });

      if (idUserAltered) {
        setMessage('Dados atualizados com sucesso');
        setNome('');
        setCpf('');
        setDtInicio('');
        setDtFim('');
        setEmail('');
        setTelefone('');
      } else {
        setMessage("Não foi possível inserir os dados.");
        setShowIcon(true);
      }
    } else {
      setMessage("Por favor, preencha todos os campos.");
      setShowIcon(true);
    }
    setShowMessage(true);
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  const formatarTelefone = (telefone: string) => {
    if (telefone.length === 10) {
      return `(${telefone.substring(0, 2)}) ${telefone.substring(2, 6)}-${telefone.substring(6)}`;
    } else if (telefone.length === 11) {
      return `(${telefone.substring(0, 2)}) ${telefone.substring(2, 7)}-${telefone.substring(7)}`;
    }
    return telefone;
  };

  const handleTelefoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawTelefone = e.target.value.replace(/\D/g, '');
    setTelefone(formatarTelefone(rawTelefone));
  };

  function validarCPF(cpf: string): boolean {
    if (cpf === '') return true;
    cpf = cpf.replace(/\D/g, '');
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;

    let soma = 0;
    let resto;
    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.charAt(9))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    return resto === parseInt(cpf.charAt(10));
  }

  return (
    <div className="min-h-screen flex items-center justify-center pt-12">
      {showMessage && (
        <MessageBox
          message={message}
          onClose={() => {
            setShowIcon(false);
            handleCloseMessage();
          }}  
          icon={showIcon ? <ExclamationCircleIcon className="w-10 h-10 mx-auto text-red-500"/> : null}
        />
      )}
      <form className="w-full max-w-md">
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-3xl font-bold leading-8 text-gray-900 border-b-4 border-indigo-500 pb-2">Cadastrar Administrativo</h2>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <label htmlFor="nome" className="block text-sm font-medium leading-6 text-gray-900">Nome</label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="nome"
                    id="nome"
                    autoComplete="given-name"
                    value={nome}
                    className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setNome(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-span-full">
                <label htmlFor="cpf" className="block text-sm font-medium leading-6 text-gray-900">CPF</label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="cpf"
                    value={cpf}
                    onChange={(e) => setCpf(e.target.value)}
                    className={`text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${cpfError ? 'ring-red-500 border-red-500' : ''}`}
                    maxLength={11}
                  />
                </div>
              </div>
              <div className="col-span-full">
                <label htmlFor="dtinicio" className="block text-sm font-medium leading-6 text-gray-900">Data Início</label>
                <div className="mt-2">
                  <input
                    type="date"
                    name="dtinicio"
                    id="dtinicio"
                    autoComplete="dtinicio"
                    value={dtInicio}
                    className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setDtInicio(e.target.value)}
                  />
                </div>
              </div>
              {IsAdministradorSupervisor && (
                <div className="col-span-full">
                  <label htmlFor="dtfim" className="block text-sm font-medium leading-6 text-gray-900">Data Fim</label>
                  <div className="mt-2">
                    <input
                      type="date"
                      name="dtfim"
                      id="dtfim"
                      autoComplete="dtfim"
                      value={dtFim}
                      className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) => setDtFim(e.target.value)}
                    />
                  </div>
                </div>
              )}
              <div className="col-span-full">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email</label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    autoComplete="given-name"
                    value={email}
                    className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-span-full">
                <label htmlFor="telefone" className="block text-sm font-medium leading-6 text-gray-900">Telefone</label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="telefone"
                    id="telefone"
                    autoComplete="given-name"
                    value={telefone}
                    className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    maxLength={15}
                    onChange={handleTelefoneChange}
                  />
                </div>
              </div>
              {IsAdministradorSupervisor && (
                <div className="col-span-full">
                  <label className="block text-sm font-medium leading-6 text-gray-900">Nível de Permissão</label>
                  <div className="mt-2 flex flex-col space-y-2">
                    {Array.from({ length: 4 }, (_, index) => {
                      const nivel = index + 1;
                      const descriptions = [
                        "Agenda Recepção",
                        "Agenda Recepção; Agendamento; Pessoas",
                        "Agenda Recepção; Agendamento; Pessoas; Geral",
                        "Agenda Recepção; Agendamento; Pessoas; Geral; Relatórios"
                      ];
                      return (
                        <label className="flex items-center" key={nivel}>
                          <input
                            type="radio"
                            name="nivelPermissao"
                            value={nivel}
                            checked={nivelPermissao === nivel}
                            onChange={handlePermissaoChange}
                            className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                          />
                          <div className="ml-2 text-gray-900 flex-1">
                            <span className="font-medium">Nível {nivel}:</span>
                            <span className="text-sm block">{descriptions[index]}</span>
                          </div>
                        </label>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6 pb-12">
          <Link to="/administrativo" className="text-indigo-600 hover:text-indigo-900">Voltar</Link>
          <button
            type="button"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleSubmit}
          >
            Salvar
          </button>
        </div>
      </form>
    </div>
  );
}
