import { useApi } from "../../hooks/useApi";
import { PessoaContext } from "./PessoaContext";
import { useContext } from 'react';
import { PacParams } from "../../types/Paciente";
import { TerapeutaParams } from "../../types/Terapeuta";
import { MonitorParams } from "../../types/Monitor";
import { HistoricoMedParams } from "../../types/HistoricoMed";
import { AdministrativoParams } from "../../types/Administrativo";

// Define tipos específicos de funções para cada tipo de ação
interface ActionFunctions {
    add: (params: GenericParams) => Promise<boolean>;
    edit: (params: GenericParams) => Promise<boolean>;
    delete: (id: string, id_user: string, id_hash?: string) => Promise<boolean>;
    fill: () => Promise<any>;
}

// Define o tipo genérico de parâmetro que pode ser passado para as funções de ação
type GenericParams = PacParams | TerapeutaParams | MonitorParams | HistoricoMedParams | AdministrativoParams;

// Define o tipo para as ações, que contém todas as funções de ação
type Actions = {
    [key in keyof ActionFunctions]: ActionFunctions[key];
};

// Função para criar as ações
const createActions = (api: any, actionPrefix: string): Actions => ({
    add: async (params: GenericParams) => {
        try {
            const data = await api[actionPrefix + 'add'](params);
            return data !== null;
        } catch (error) {
            console.error('Erro na chamada da API:', error);
            return false;
        }
    },
    edit: async (params: GenericParams) => {
        try {
            const data = await api[actionPrefix + 'edit'](params);
            return data !== null;
        } catch (error) {
            console.error('Erro na chamada da API:', error);
            return false;
        }
    },
    delete: async (id: string, id_user: string) => {
        try {
            const data = await api[actionPrefix + 'delete'](id, id_user);
            return data !== null;
        } catch (error) {
            console.error('Erro na chamada da API:', error);
            return false;
        }
    },
    fill: async (id?: string, codProc?: string, flagAgenda?: boolean, salaRef?: string | null) => {
        try {
            return await api[actionPrefix + 'fill'](id, codProc, flagAgenda, salaRef);
        } catch (error) {
            console.error('Erro na chamada da API:', error);
            return null;
        }
    }
});

export const PessoaProvider = ({ children }: { children: JSX.Element }) => {
    const api = useApi();

    const pacActions = createActions(api, 'pac');
    const terapActions = createActions(api, 'terap');
    const monitorActions = createActions(api, 'monitor');
    const administrativoActions = createActions(api, 'adm');
    const historicomedActions = createActions(api, 'historico');

    return (
        <PessoaContext.Provider value={{
            pacActions,
            terapActions,
            monitorActions,
            administrativoActions,
            historicomedActions
        }}>
            {children}
        </PessoaContext.Provider>
    );
}

export const usePessoaContext = () => useContext(PessoaContext);
