import { Button, Card, CardFooter, Typography } from "@material-tailwind/react";
import { useContext, useEffect, useState } from "react";
import { GeralContext } from "../../../../contexts/Geral/GeralContext";
import { useNavigate } from "react-router-dom";
import {
  MagnifyingGlassIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { Tooltip } from "@mui/material";
import { AuthContext } from "../../../../contexts/Auth/AuthContext";
import DetailModal from "../../../../components/Utils/DetailModal";

const TABLE_HEAD = ["Área de Atuação/Formação", "Atividades", "Ação"];

const ITEMS_PER_PAGE = 20;

interface AtividadeSelecionada {
  id_ativ: string;
}

interface EspecItem {
  id_espec: string;
  especialidade: string;
  atividades_selecionadas: AtividadeSelecionada[];
}

interface DeleteItemParams {
  id_espec: string;
  especialidade: string;
}

interface AtividadeItem {
  id_ativ: string;
  atividade: string;
}

export function GerenciaEspecialidade() {
  const { especActions, atividadeActions } = useContext(GeralContext);
  const [tableData, setTableData] = useState<EspecItem[]>([]);
  const [especialidadeFilter, setEspecialidadeFilter] = useState<string>('');
  const [atividades, setAtividades] = useState<AtividadeItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<DeleteItemParams | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [, setSelectedItem] = useState<EspecItem | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<{ [key: string]: any }>({});
  const auth = useContext(AuthContext);
  const idUser = auth.user?.id_user;
  const IsAdministrador = !!(auth.user?.ocupacao === 'Administrador' || auth.user?.admin);
  const IsAdministradorSupervisor = !!(auth.user?.ocupacao === 'Administrador' || auth.user?.admin || auth.user?.supervisor);
  const IsTerapeuta = !!(auth.user?.ocupacao === 'Terapeuta');
  const IsMonitor = !!(auth.user?.ocupacao === 'Monitor');
  const IsAdministrativo = !!(auth.user?.ocupacao === 'Administrativo');
  const AdministrativoPermissaoGeral = (auth.user?.permissao ?? 0) >= 3;

  useEffect(() => {
    const fetchItemData = async () => {
      setIsLoading(true); 
      try {
        const ItemData = await especActions.fill();
        setTableData(ItemData);
        console.log('Especialidades:', ItemData);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchItemData();
  }, [especActions]);

  useEffect(() => {
    const fetchAtividadesData = async () => {
        try {
            const AtividadesData = await atividadeActions.fill();
            setAtividades(AtividadesData);
            console.log('Atividades:', AtividadesData);
        } catch (error) {
            console.error("Erro ao buscar dados das atividades:", error);
        }
    };
    fetchAtividadesData();
  }, [atividadeActions]);

  useEffect(() => {
    if (especialidadeFilter !== '') {
      setCurrentPage(1);
    }
  }, [especialidadeFilter]);

  const filteredData = Array.isArray(tableData) ? tableData.filter(({especialidade}) => {
    if (especialidade) {
      const especialidadeMatches = especialidadeFilter ? especialidade.toString().toLowerCase().includes(especialidade.toLowerCase()) : true;
      return especialidadeMatches
    }
    return true;
  }) : [];
  
  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);

  const visibleData = filteredData.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  const handleDeleteButtonClick = (deleteItem: DeleteItemParams) => {
    setDeleteItem(deleteItem);
    setConfirmDelete(true);
  };

  const handleButtonClick = () => {
    navigate('/especialidade/add');
  };

  const handleEditButtonClick = (item: EspecItem) => {
    setSelectedItem(item);
    navigate('/especialidade/edit', { state: { selectedItem: item } });
  };

  const first = () => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }
  };

  const ult = () => {
    if (currentPage < totalPages) {
      setCurrentPage(totalPages);
    }
  };

  const next = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } 
  };

  const prev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const confirmDeleteItem = () => {
    if (deleteItem && idUser && IsAdministrador) {
      especActions.delete(String(deleteItem.id_espec), idUser)
        .then(() => {
          setDeleteItem(null);
          setConfirmDelete(false);
          window.location.reload();
        })
        .catch((error) => {
          console.error('Erro ao deletar o item:', error);
        });
    } else {
      alert('Você não possui permissão, caso necessite realizar a operação entre em contato com o administrador para alterar as permissões.');
    }
  };

  const cancelDeleteItem = () => {
    setDeleteItem(null);
    setConfirmDelete(false);
  };

  const handleViewButtonClick = (item: EspecItem) => {
    setModalData({
      "Especialidade": item.especialidade?item.especialidade:'',
      "Atividades Selecionadas": item.atividades_selecionadas.map((ativ) => atividades.find(a => a.id_ativ === ativ.id_ativ)?.atividade).join(", ")
    });
    setIsModalOpen(true);
  };
  

  return (
    <div>
      <div className="p-4 mb-4 flex flex-col md:flex-row md:items-center md:space-x-4 bg-gray-100 rounded-lg">
        {/* Proprietário Filter */}
        <Button 
          placeholder='' 
          variant="outlined" 
          size="sm"
          onClick={handleButtonClick}
          className={'p-2 my-2 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-normal rounded-lg text-sm me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'}
        >
          <PlusIcon className='w-6 h-6' />
        </Button>
        <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-2 my-4">
          <label htmlFor="especialidadeFilter" className="text-black-700 md:mt-0 mt-2">
            Especialidade:
          </label>
          <div className="relative">
            <input
              type="text"
              id="especialidadeFilter"
              value={especialidadeFilter}
              onChange={(e) => setEspecialidadeFilter(e.target.value)}
              className="border border-gray-300 pl-8 pr-2 py-2 rounded"
            />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap md:flex-nowrap items-center pl-24">
          <h2 className="text-3xl font-bold font-mono relative pr-4 italic text-transparent bg-clip-text bg-gradient-to-r to-green-600 from-blue-400 mb-4 md:mb-0">
            Registros
          </h2>
          <h2 className="text-3xl font-bold font-mono relative pr-4 italic text-transparent bg-clip-text bg-gradient-to-r to-blue-600 from-green-400 mb-4 md:mb-0">
            Área de Atuação/Formação
          </h2>
        </div>
      </div>
      <Card placeholder='' className="h-full w-full overflow-auto">
        <table className="w-full min-w-max table-auto text-center">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                  <Typography
                    placeholder=''
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
          {isLoading ? (
              <tr>
                <td colSpan={TABLE_HEAD.length} className="p-4">Carregando...</td>
              </tr>
            ) : visibleData.map((item, index) => (
              <tr key={index} className={index % 2 === 0 ? "bg-gray-200" : "bg-white"}>
                <td className="p-4 border-b border-blue-gray-100">
                  <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
                    {item.especialidade || ''}
                  </Typography>
                </td>
                <td className="p-4 border-b border-blue-gray-100">
                  <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
                    {item.atividades_selecionadas && item.atividades_selecionadas.length > 0 ? item.atividades_selecionadas.map((ativ, index) => {
                      // Encontrar a atividade correspondente na lista de atividades
                      const atividadeCorrespondente = atividades.find(c => c.id_ativ === ativ.id_ativ);
                      return (
                        atividadeCorrespondente ? (
                          <span key={index}>
                            {atividadeCorrespondente.atividade}
                            <br />
                          </span>
                        ) : null
                      );
                    }) : 'Nenhuma Atividade'}
                  </Typography>
                </td>
                <td className="border-b border-blue-gray-100 p-2">
                <div className="flex space-x-2 justify-center">
                  <Tooltip title="Visualizar">
                    <Button 
                      placeholder=''
                      className="w-8 h-8 flex items-center justify-center p-1 bg-blue-500 hover:bg-blue-700 text-white rounded-full"
                      onClick={() => handleViewButtonClick(item)}
                    >
                      <MagnifyingGlassIcon className="h-5 w-5" />
                    </Button>
                  </Tooltip>
                  {AdministrativoPermissaoGeral|| IsAdministradorSupervisor? <Tooltip title="Atualizar Informações" placement="top">
                    <Typography
                      placeholder=''
                      variant="small"
                      as="button"
                      onClick={() => handleEditButtonClick(item)}
                      color="blue-gray"
                      className="w-8 h-8 flex items-center justify-center p-1 bg-green-500 hover:bg-green-700 text-white rounded-full"
                      >
                      <PencilIcon className="w-6 h-6" />
                    </Typography>
                  </Tooltip>:''}
                  {IsAdministrador? <Tooltip title="Deletar" placement="top">
                    <Button
                      placeholder=""
                      color="blue-gray"
                      className="w-8 h-8 flex items-center justify-center p-1 bg-red-500 hover:bg-red-700 text-white rounded-full"
                      onClick={() => handleDeleteButtonClick(item)}
                    >
                      <TrashIcon className="w-6 h-6" />
                    </Button>
                  </Tooltip>:''}
                </div>
              </td>
              </tr>
            ))}
          </tbody>
          {/* Janela de confirmação */}
          {confirmDelete && deleteItem && (
            <div className="fixed inset-0 z-10 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
              <div className="relative w-auto max-w-3xl mx-auto my-6">
                <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                  <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blue-gray-200">
                    <h3 className="text-3xl font-semibold">Confirmar exclusão</h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={cancelDeleteItem}
                    >
                      <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                    </button>
                  </div>
                  <div className="relative p-6 flex-auto">
                    <p className="my-4 text-blue-gray-500 text-lg leading-relaxed">Tem certeza que deseja excluir a Especialidade ID: {deleteItem.id_espec} / Especialidade: {deleteItem.especialidade}?</p>
                  </div>
                  <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blue-gray-200">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={cancelDeleteItem}
                    >
                      Cancelar
                    </button>
                    <button
                      className="bg-red-700 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={confirmDeleteItem}
                    >
                      Confirmar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </table>
      </Card>
      <CardFooter placeholder='' className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
          Página <strong className="text-blue-gray-900">{currentPage}</strong> de{" "}
          <strong className="text-blue-gray-900">{totalPages}</strong>
        </Typography>
        <div className="flex gap-2">
          <Button 
            placeholder=''
            variant="outlined" 
            size="sm"
            onClick={first}
            disabled={currentPage === 1}
            className="p-2"
          >
            Primeira
          </Button>
          <Button 
            placeholder='' 
            variant="outlined" 
            size="sm"
            onClick={prev}
            disabled={currentPage === 1}
            className="p-2"
          >
            Anterior
          </Button>
          <Button 
            placeholder='' 
            variant="outlined" 
            size="sm" 
            onClick={next}
            disabled={currentPage === totalPages}
            className="p-2"
          >
            Próximo
          </Button>
          <Button 
            placeholder='' 
            variant="outlined" 
            size="sm"
            onClick={ult}
            disabled={currentPage === totalPages}
            className="p-2"
          >
            Última
          </Button>
        </div>
      </CardFooter>
      <DetailModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        data={modalData}
      />
    </div>
  );
}
