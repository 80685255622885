import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { GeralContext } from "../../../contexts/Geral/GeralContext";
import RelAgendaPaciente from "../../../components/Utils/RelatoriosAgendaPaciente";
import RelAgendaTerapeuta from "../../../components/Utils/RelatoriosAgendaTerapeuta";
import { PessoaContext } from "../../../contexts/Pessoa/PessoaContext";
import RelAgendaPacienteExcel from "../../../components/Utils/RelatoriosAgendaPacienteExcel";
import RelAgendaTerapeutaExcel from "../../../components/Utils/RelatoriosAgendaTerapeutaExcel";

interface AgendaItem {
  id_agenda: string | null;
  nome_paciente: string;
  id_pac: string;
  codconvenio: string;
  dt_evento: string;
  hr_inicio: string;
  hr_fim: string;
  nome_terapeuta: string;
  id_terap: string;
  paciente: string;
  convenio: string;
  n_sala: string;
  codproc: string;
  atividade: string;
  n_pessoas: string;
  horario: string;
  procedimento: string;
  tmp_atividade: string;
  situacao: string;
  observacao: string;
}

interface PacienteItem {
  id_pac: string;
  id_seq: string;
  cpf: string;
  nome: string;
  dt_nasc: string;
  genero: string;
  med_resp: string;
  crm: string;
  tel_med: string;
  n_cartao: string;
  periodo: string;
  dias: number;
  sessoes: number;
  cep: string;
  estado: string;
  cidade: string;
  bairro: string;
  logradouro: string;
  numeral: string;
  complemento: string;
  situacao: boolean; //
  dt_inicio: string;
  dt_fim: string;
}

interface TerapeutaItem {
  id_hash: string;
  id_terap: string;
  id_espec: string;
  matricula: string;
  especialidade: string;
  nome: string;
  cpf: string;
  conselho: string;
  dt_inicio: string;
  dt_fim: string;
  email: string;
  telefone: number;
  permissao: {
    administrador: boolean;
    supervisor: boolean;
  };
}

export default function Relatorios() {
  const { pacActions, terapActions } = useContext(PessoaContext);
  const [dateInicio, setDateInicio] = useState(moment());
  const [dateFim, setDateFim] = useState(moment().add(7, "days"));
  const [pacData, setPacData] = useState<PacienteItem[]>([]);
  const [agendaItem, setAgendaItem] = useState<AgendaItem[]>([]);
  const { relatorioActions } = useContext(GeralContext);
  const [tipoRelatorio, setTipoRelatorio] = useState<string>("paciente");
  const [eventSituation, setEventSituation] = useState("Todas");
  const [fields, setFields] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pacienteSelecionado, setPacienteSelecionado] = useState<string>("");
  const [terapeutaSelecionado, setTerapeutaSelecionado] = useState<string>("");
  const [origemRelatorio, setOrigemRelatorio] = useState<string>("oficial");
  const [terapData, setTerapData] = useState<TerapeutaItem[]>([]);

  useEffect(() => {
    const fetchAgendaItem = async () => {
      setLoading(true);
      const agendaData = await relatorioActions.fill(
        dateInicio.format("YYYY-MM-DD"),
        dateFim.format("YYYY-MM-DD"),
        origemRelatorio
      );
  
      let filteredAgenda = agendaData;
  
      // Filtro por situação
      if (eventSituation !== "Todas") {
        filteredAgenda = filteredAgenda.filter(
          (item: AgendaItem) => item.situacao === eventSituation
        );
      }
  
      // Filtro por nome de paciente (exemplo de filtro adicional)
      if (pacienteSelecionado) {
        filteredAgenda = filteredAgenda.filter(
          (item: AgendaItem) => item.nome_paciente === pacienteSelecionado
        );
      }

      // Filtro por nome de paciente (exemplo de filtro adicional)
      if (terapeutaSelecionado) {
        filteredAgenda = filteredAgenda.filter(
          (item: AgendaItem) => item.nome_terapeuta === terapeutaSelecionado
        );
      }
  
      setAgendaItem(filteredAgenda);
      setFields(["nome_paciente", "situacao"]);
      setLoading(false);
    };
  
    fetchAgendaItem();
  }, [relatorioActions, dateInicio, dateFim, eventSituation, pacienteSelecionado, terapeutaSelecionado, terapData, origemRelatorio]);
  

  
  useEffect(() => {
    const fetchPacData = async () => {
      setLoading(true);
      let PacData: PacienteItem[];
      try {
        PacData = await pacActions.fill();
        setPacData(PacData);
        console.log(PacData);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchPacData();
  }, [pacActions]);

  useEffect(() => {
    const fetchTerapData = async () => {
      setLoading(true);
      let TerapData: TerapeutaItem[];
      try {
        TerapData = await terapActions.fill();
        setTerapData(TerapData);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTerapData();
  }, [terapActions]);

  return (
    <div className="max-w-5xl mx-auto p-6 bg-white shadow-md rounded-md">
      <h1 className="text-xl font-semibold text-gray-800 text-center mb-6">
        Relatórios
      </h1>
      <form className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Data de Início */}
        <div className="flex flex-col">
          <label
            htmlFor="dateinicio"
            className="text-sm font-semibold text-gray-700 mb-2"
          >
            Data Início
          </label>
          <input
            type="date"
            id="dateinicio"
            value={moment(dateInicio).format("YYYY-MM-DD")}
            onChange={(e) => setDateInicio(moment(e.target.value))}
            className="px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        {/* Data de Fim */}
        <div className="flex flex-col">
          <label
            htmlFor="datefim"
            className="text-sm font-semibold text-gray-700 mb-2"
          >
            Data Fim
          </label>
          <input
            id="datefim"
            type="date"
            value={moment(dateFim).format("YYYY-MM-DD")}
            onChange={(e) => setDateFim(moment(e.target.value))}
            className="px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
          />
        </div>

        <div className="flex flex-col">
          <label
            htmlFor="situation"
            className="text-sm font-semibold text-gray-700 mb-2"
          >
            Origem
          </label>
          <select
            id="situation"
            value={origemRelatorio}
            onChange={(e) => setOrigemRelatorio(e.target.value)}
            className="px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
          >
            <option value="oficial">Agenda Oficial</option>
            <option value="recepcao">Agenda Recepção</option>
          </select>
        </div>

        {/* Tipo de Relatório */}
        <div className="flex flex-col">
          <label
            htmlFor="tiporelatorio"
            className="text-sm font-semibold text-gray-700 mb-2"
          >
            Tipo de Relatório
          </label>
          <select
            id="tiporelatorio"
            value={tipoRelatorio}
            onChange={(e) => setTipoRelatorio(e.target.value)}
            className="px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
          >
            <option value="paciente">Paciente</option>
            <option value="terapeuta">Terapeuta</option>
          </select>
        </div>

        {/* Situação */}
        <div className="flex flex-col">
          <label
            htmlFor="situation"
            className="text-sm font-semibold text-gray-700 mb-2"
          >
            Situação
          </label>
          <select
            id="situation"
            value={eventSituation}
            onChange={(e) => setEventSituation(e.target.value)}
            className="px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
          >
            <option value="Todas">Todas</option>
            <option value="Agendado">Agendado</option>
            <option value="Presente">Presente</option>
            <option value="Falta">Falta</option>
            <option value="Sai após este atendimento">
              Sai após este atendimento
            </option>
            <option value="Avaliação">Avaliação</option>
            <option value="Devolutiva">Devolutiva</option>
            <option value="Troca de horário ou terapeuta">
              Troca de horário ou terapeuta
            </option>
            <option value="Encaixe">Encaixe</option>
            <option value="Desmarcado pela clínica">
              Desmarcado pela clínica
            </option>
            <option value="Controle Interno">Controle Interno</option>
          </select>
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="paciente"
            className="text-sm font-semibold text-gray-700 mb-2"
          >
            Paciente
          </label>
          <select
            id="paciente"
            value={pacienteSelecionado}
            onChange={(e) => setPacienteSelecionado(e.target.value)}
            className="px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
          >
          <option value="">Selecione o paciente</option>
          {pacData.sort((a, b) => a.nome.localeCompare(b.nome)).map((agenda) => (
            <option key={agenda.id_pac} value={agenda.nome}>
              {agenda.nome}
            </option>
          ))}
          </select>
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="situaterapeutation"
            className="text-sm font-semibold text-gray-700 mb-2"
          >
            Terapeuta
          </label>
          <select
              id="terapeuta"
              value={terapeutaSelecionado}
              onChange={(e) => setTerapeutaSelecionado(e.target.value)}
              className="px-4 py-2 text-sm text-gray-700 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
            >
            <option value="">Selecione o terapeuta</option>
            {terapData.sort((a, b) => a.nome.localeCompare(b.nome)).map((agenda) => (
              <option key={agenda.id_terap} value={agenda.nome}>
                {agenda.nome}
              </option>
            ))}
            </select>
          </div>
      </form>

      {/* Renderização do Relatório */}
      <div className="mt-8">
      {tipoRelatorio === "paciente" && (
        <RelAgendaPaciente
          data={agendaItem}
          fields={fields}
          loading={loading} // Passa a variável loading como prop
        />
      )}
      {tipoRelatorio === "terapeuta" && (
        <RelAgendaTerapeuta
          data={agendaItem}
          fields={fields}
          loading={loading} // Passa a variável loading como prop
        />
      )}
    </div>
    <div className="mt-8">
      {tipoRelatorio === "paciente" && (
        <RelAgendaPacienteExcel
          data={agendaItem}
          fields={fields}
          loading={loading} // Passa a variável loading como prop
        />
      )}
      {tipoRelatorio === "terapeuta" && (
        <RelAgendaTerapeutaExcel
          data={agendaItem}
          fields={fields}
          loading={loading} // Passa a variável loading como prop
        />
      )}
    </div>
    </div>
  );
}
