import React, { useState, useEffect } from "react";
import { AgendaManha } from "./Manha";
import { AgendaTarde } from "./Tarde";
import Legend from "../../components/Utils/Legend";
import moment from "moment";

const Agenda: React.FC = () => {
  const [selectedAgenda, setSelectedAgenda] = useState<"manha" | "tarde">(
    () => {
      return (
        (localStorage.getItem("selectedAgenda") as "manha" | "tarde") || "manha"
      );
    }
  );

  const [selectedDate, setSelectedDate] = useState(() => {
    const storedDate = localStorage.getItem("selectedDateManha");
    return storedDate ? new Date(storedDate) : new Date();
  });

  const [refreshKey, setRefreshKey] = useState(0); // Estado para forçar a recriação

  // Atualiza o localStorage quando a data ou agenda mudam
  useEffect(() => {
    localStorage.setItem("selectedAgenda", selectedAgenda);
    localStorage.setItem("selectedDateManha", selectedDate.toISOString());
  }, [selectedAgenda, selectedDate]);

  const handlePreviousDate = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(selectedDate.getDate() - 1);
    setSelectedDate(newDate);
    localStorage.setItem("selectedDateManha", newDate.toISOString());
  
    setRefreshKey((prev) => prev + 1); // Força recriação
  };

  const handleNextDate = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(selectedDate.getDate() + 1);
    setSelectedDate(newDate);
    localStorage.setItem("selectedDateManha", newDate.toISOString());
  
    setRefreshKey((prev) => prev + 1); // Força recriação
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(e.target.value);
    setSelectedDate(newDate);
    setRefreshKey((prev) => prev + 1); // Força recriação
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      {/* Área de seleção Manhã/Tarde */}
      <nav className="flex justify-center items-center space-x-2 p-3 bg-gray-200">
        <button
          onClick={() => setSelectedAgenda("manha")}
          className={`px-4 py-2 rounded ${
            selectedAgenda === "manha"
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-gray-700"
          }`}
        >
          Manhã
        </button>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => setSelectedAgenda("tarde")}
            className={`px-4 py-2 rounded ${
              selectedAgenda === "tarde"
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            Tarde
          </button>
          <Legend />
        </div>
      </nav>

      {/* Controle de data */}
      <div className="flex flex-col items-center mx-4">
        <div className="text-center mb-4">
          <h4 className="font-bold">
            {moment(selectedDate).format("dddd, D [de] MMMM [de] YYYY")}
          </h4>
        </div>
        <div className="flex items-center">
          <button
            onClick={handlePreviousDate}
            className="px-4 py-2 bg-blue-500 text-white rounded-l-lg hover:bg-blue-600"
          >
            Anterior
          </button>
          <input
            type="date"
            value={moment(selectedDate).format("YYYY-MM-DD")}
            onChange={handleDateChange}
            className="px-4 py-2 mx-2 text-center border rounded-md"
          />
          <button
            onClick={handleNextDate}
            className="px-4 py-2 bg-blue-500 text-white rounded-r-lg hover:bg-blue-600"
          >
            Próximo
          </button>
        </div>
      </div>

      {/* Área da agenda */}
       <div key={refreshKey} className="flex-1 bg-white p-3 rounded shadow-md">
        {selectedAgenda === "manha" && <AgendaManha />}
        {selectedAgenda === "tarde" && <AgendaTarde />}
      </div>
    </div>
  );
};

export default Agenda;
