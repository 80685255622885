import { Button, Card, CardFooter, Typography } from "@material-tailwind/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PlusIcon,
  TrashIcon,
  PencilIcon,
  MagnifyingGlassIcon,
  ClipboardIcon
} from '@heroicons/react/24/outline'
import { PessoaContext } from "../../../../contexts/Pessoa/PessoaContext";
import { Tooltip } from "@mui/material";
import { AuthContext } from "../../../../contexts/Auth/AuthContext";
import { GeralContext } from "../../../../contexts/Geral/GeralContext";
import DetailModal from "../../../../components/Utils/DetailModal";

const TABLE_HEAD = ["ID","Nome", "Data de Nascimento","Genero", "Responsável", "Convenio",  "Endereço", "Ação" ];

const ITEMS_PER_PAGE = 20; 

interface Responsavel {
  nome: string;
  filiacao: string;
  telefone: string;
  cpf: string;
  resp: boolean;
}

interface ConvenioSelecionado {
  codconvenio: string;
  ncartao: string;
}

interface PacienteItem {
  id_pac: string;
  id_seq: string;
  cpf: string;
  nome: string; 
  dt_nasc: string; 
  genero: string;
  med_resp: string;
  crm: string;
  tel_med: string;
  responsaveis:Responsavel[]; 
  n_cartao: string; 
  periodo: string;
  dias: number;
  sessoes: number; 
  cep: string; 
  estado: string; 
  cidade: string; 
  bairro: string;
  logradouro: string; 
  numeral: string; 
  complemento: string;
  convenios_selecionados: ConvenioSelecionado[]; // Novo campo
  situacao: boolean; //
  dt_inicio: string;
  dt_fim: string;
}

interface SortConfig {
  key: keyof PacienteItem;
  direction: 'ascending' | 'descending';
}


interface DeleteItemParams {
  id_pac: string;
  nome: string;
}

interface ConvenioItem {
  codconvenio: string;
  convenio: string;
}

export function GerenciaPaciente() {
  const { pacActions } = useContext(PessoaContext);
  const {convenioActions} = useContext(GeralContext);
  const [tableData, setTableData] = useState<PacienteItem[]>([]);
  const [nomeFilter, setNomeFilter] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<DeleteItemParams | null | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [convenios, setConvenios] = useState<ConvenioItem[]>([]);
  const [, setSelectedItem] = useState<PacienteItem | null>(null);
  const [, setSelectedId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<{ [key: string]: any }>({});
  const [situacaoFilter, setSituacaoFilter] = useState("ativos"); // Padrão: "ativos"
  const auth = useContext(AuthContext);
  const idUser = auth.user?.id_user;
  const IsAdministrador = !!(auth.user?.ocupacao === 'Administrador' || auth.user?.admin);
  const IsAdministradorSupervisor = !!(auth.user?.ocupacao === 'Administrador' || auth.user?.admin || auth.user?.supervisor);
  const IsTerapeuta = !!(auth.user?.ocupacao === 'Terapeuta');
  const IsMonitor = !!(auth.user?.ocupacao === 'Monitor');
  const IsAdministrativo = !!(auth.user?.ocupacao === 'Administrativo');
  const AdministrativoPermissaoPessoa = (auth.user?.permissao ?? 0) >= 2;
const [sortConfig, setSortConfig] = useState<SortConfig>({ key: 'id_seq', direction: 'ascending' });
const handleSort = (key: keyof PacienteItem) => {
  let direction: 'ascending' | 'descending';

  if (sortConfig.key === key) {
    // Alterna a direção se a mesma coluna for clicada
    direction = sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
  } else {
    // Define a direção como 'ascending' para novas colunas
    direction = 'ascending';
  }

  setSortConfig({ key, direction });
  };

  useEffect(() => {
    const fetchItemData = async () => {
      setIsLoading(true);
      let ItemData: PacienteItem[];
      try {
        ItemData = await pacActions.fill();
        setTableData(ItemData);
        console.log(ItemData);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchItemData();
  }, [pacActions]);

  useEffect(() => {
    if (nomeFilter !== '') {
      setCurrentPage(1);
    }
  }, [nomeFilter]);
  
  useEffect(() => {
    const fetchConvenioData = async () => {
        try {
            const convenioData = await convenioActions.fill();
            setConvenios(convenioData);
        } catch (error) {
            console.error("Erro ao buscar dados dos convenios:", error);
        }
    };
    fetchConvenioData();
}, [convenioActions]);
  
  const removeAccents = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

const filteredData = Array.isArray(tableData)
  ? tableData.filter(({ nome, situacao }) => {
      const normalizedNome = nome ? removeAccents(nome.toString().toLowerCase()) : ''; // Verificação para evitar null
      const normalizedFilter = removeAccents(nomeFilter.toLowerCase());
      const nomeMatches = nomeFilter ? normalizedNome.includes(normalizedFilter) : true;

      // Filtrar com base na seleção do Listbox
      const situacaoMatches =
        situacaoFilter === "todos" ? true
        : situacaoFilter === "ativos" ? situacao === true
        : situacao === false; // Para "inativos"

      return nomeMatches && situacaoMatches;
    })
  : [];



  const fieldMap: { [key: string]: keyof PacienteItem } = {
    "id": "id_seq",
    "nome": "nome",
    "datadenascimento": "dt_nasc",
    "genero": "genero",
    "responsável": "responsaveis",
    "endereço": "logradouro",
    "convenio": "convenios_selecionados"  // Certifique-se que este campo está correto
  };
  
  const sortedData = [...filteredData].sort((a, b) => {
    const keyA = fieldMap[sortConfig.key] || sortConfig.key;

    let aKey = a[keyA] !== undefined ? String(a[keyA]) : '';
    let bKey = b[keyA] !== undefined ? String(b[keyA]) : '';

    if (keyA === 'convenios_selecionados') {
        // Buscar o nome do convênio usando o `codconvenio` do primeiro convênio da lista
        const aConvenio = a.convenios_selecionados[0]
            ? convenios.find(c => c.codconvenio === a.convenios_selecionados[0].codconvenio)?.convenio || ''
            : '';
        const bConvenio = b.convenios_selecionados[0]
            ? convenios.find(c => c.codconvenio === b.convenios_selecionados[0].codconvenio)?.convenio || ''
            : '';

        aKey = aConvenio;
        bKey = bConvenio;
    }

    const comparison = aKey.localeCompare(bKey, undefined, { sensitivity: 'base' });
    return sortConfig.direction === 'ascending' ? comparison : -comparison;
});

  
  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);

  const visibleData = sortedData.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);


  const handleDeleteButtonClick = (deleteItem:DeleteItemParams) => {
      setDeleteItem(deleteItem);
      setConfirmDelete(true);
  };

  const handleButtonClick = () => {
    navigate('/paciente/add');
  };

  const handleEditButtonClick = (item: PacienteItem) => {
    setSelectedItem(item);
    navigate('/paciente/edit', { state: { selectedItem: item } });
  };

  const handleProntuarioButtonClick = (item: string) => {
    setSelectedId(item);
    navigate('/paciente/prontuario', { state: { setSelectedId: item } });
  };

  const first = () => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }
  };

  const ult = () => {
    if (currentPage < totalPages) {
      setCurrentPage(totalPages);
    }
  };

  const next = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const confirmDeleteItem = () => {
    if (deleteItem && idUser && IsAdministrador && IsAdministrador === true) {

      pacActions.delete(String(deleteItem.id_pac), idUser)
      .then(() => {

          setDeleteItem(null);
          setConfirmDelete(false);
          window.location.reload();
        })
        .catch((error) => {
          console.error('Erro ao deletar o item:', error);
        });
    } else {
        alert('Você não possui permissão, caso necessite realizar a operação entre em contato com o administrador para alterar as permissões.');
    }
  };

  const cancelDeleteItem = () => {
    setDeleteItem(null);
    setConfirmDelete(false);
  };

  const handleViewButtonClick = (item: PacienteItem) => {
    // Formatar responsáveis
    const formattedResponsaveis = item.responsaveis?.map((resp, index) => (
      `${resp.nome} (${resp.filiacao} / ${resp.telefone})`
    )).join('\n') || 'Nenhum responsável';
  
    // Formatar convênios selecionados
    const formattedConvenios = item.convenios_selecionados?.map((conv, index) => {
      const convenioInfo = convenios.find(c => c.codconvenio === conv.codconvenio);
      console.log(convenios);
      return convenioInfo
        ? `${convenioInfo.convenio} - Cartão: ${conv.ncartao || 'N/A'}`
        : 'Desconhecido';
    }).join('\n') || 'Nenhum convênio';
  
    setModalData({
      "Nome": item.nome ? item.nome : '',
      "Identificação": item.id_seq ? item.id_seq : '',
      "Genero": item.genero === 'M' ? 'Masculino' : 'Feminino',
      "CPF": item.cpf ? item.cpf : '',
      "Nascimento": item.dt_nasc ? new Date(item.dt_nasc).toLocaleDateString('pt-BR', { timeZone: 'UTC' }) : '',
      "Médico Responsável": item.med_resp ? item.med_resp : '',
      "CRM": item.crm ? item.crm : '',
      "Responsáveis": formattedResponsaveis,
      "Telefone do Médico": item.tel_med ? item.tel_med : '',
      "Sessões": item.sessoes ? item.sessoes : '',
      "CEP": item.cep ? item.cep : '',
      "Logradouro": item.logradouro ? item.logradouro : '',
      "Numeral": item.numeral ? item.numeral : '',
      "Cidade": item.cidade ? item.cidade : '',
      "Estado": item.estado ? item.estado : '',
      "Convenios Selecionados": formattedConvenios,
      "Data de Início": item.dt_inicio?new Date(item.dt_inicio).toLocaleDateString('pt-br', { timeZone: 'UTC' }):'',
      "Data de Fim": item.dt_fim?new Date(item.dt_fim).toLocaleDateString('pt-br', { timeZone: 'UTC' }):''
    });
    setIsModalOpen(true);
  };  
  
  return (
    <div>
      <div className="p-4 mb-4 flex flex-col md:flex-row md:items-center md:space-x-4 bg-gray-100 rounded-lg">
        <Button 
          placeholder='' 
          variant="outlined" 
          size="sm"
          onClick={handleButtonClick}
          className={'p-2 my-2 focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-normal rounded-lg text-sm me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'}
          >
          <PlusIcon className='w-6 h-6' />
        </Button>
        <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-2 my-4">
          <label htmlFor="pacienteFilter" className="text-black-700 md:mt-0 mt-2">
            Paciente
          </label>
          <div className="relative">
            <input
              type="text"
              id="pacienteFilter"
              value={nomeFilter}
              onChange={(e) => setNomeFilter(e.target.value)}
              className="border border-gray-300 pl-8 pr-2 py-2 rounded"
            />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-2 my-4">
          <label htmlFor="situacaoFilter" className="text-black-700">
            Situação:
          </label>
          <select
            id="situacaoFilter"
            value={situacaoFilter}
            onChange={(e) => setSituacaoFilter(e.target.value)}
            className="border border-gray-300 rounded px-2 py-1"
          >
            <option value="todos">Todos</option>
            <option value="ativos">Ativos</option>
            <option value="inativos">Inativos</option>
          </select>
        </div>
        <div className="flex flex-wrap md:flex-nowrap items-center pl-24">
          <h2 className="text-3xl font-bold font-mono relative pr-4 italic text-transparent bg-clip-text bg-gradient-to-r to-green-600 from-blue-400 mb-4 md:mb-0">
            Registros
          </h2>
          <h2 className="text-3xl font-bold font-mono relative pr-4 italic text-transparent bg-clip-text bg-gradient-to-r to-blue-600 from-green-400 mb-4 md:mb-0">
            Pacientes
          </h2>
        </div>
      </div>
      <Card placeholder='' className="h-full w-full overflow-auto">
        <table className="w-full min-w-max table-auto text-center">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => {
              const key = head.toLowerCase().replace(/\s+/g, '') as keyof PacienteItem;
              return (
                <th key={head} className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 cursor-pointer" onClick={() => handleSort(key)}>
                  <Typography placeholder='' variant="small" color="blue-gray" className="font-normal leading-none opacity-70">
                    {head} {sortConfig.key === key ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : ''}
                  </Typography>
                </th>
              );
            })}
          </tr>
        </thead>
          <tbody>
          {isLoading ? (
              <tr>
                <td colSpan={TABLE_HEAD.length} className="p-4">Carregando...</td>
              </tr>
            ) : visibleData.map((item, index) => (
              <tr key={index} className={index % 2 === 0 ? "bg-gray-200" : "bg-white"}>
                {/* ... (table cells) */}
              <td className="p-4 border-b border-blue-gray-100">
                <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
                  {item.id_seq?item.id_seq:''}
                </Typography>
              </td>
              <td className="p-4 border-b border-blue-gray-100">
                <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
                  {item.nome?item.nome:''}
                </Typography>
              </td>
              <td className="p-4 border-b border-blue-gray-100">
                <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
                  {new Date(item.dt_nasc).toLocaleDateString('pt-BR', {
                    timeZone: 'UTC'
                  })}
                </Typography>
              </td>
              <td className="p-4 border-b border-blue-gray-100">
                <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
                  {item.genero?item.genero:''}
                </Typography>
              </td>
              <td className="p-4 border-b border-blue-gray-100">
                <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
                  {item.responsaveis && item.responsaveis.length > 0 ? item.responsaveis.map((resp, index) => (
                    <span key={index}>
                      {`${resp.nome} (${resp.filiacao} / ${resp.telefone})`}
                      <br />
                    </span>
                  )) : ''}
                </Typography>
              </td>
              <td className="p-4 border-b border-blue-gray-100">
                <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
                  {item.convenios_selecionados && item.convenios_selecionados.length > 0 ? item.convenios_selecionados.map((conv, index) => {
                    const convenioInfo = convenios.find(c => c.codconvenio === conv.codconvenio);
                    return (
                      <span key={index}>
                        {convenioInfo ? `${convenioInfo.convenio} - Cartão: ${conv.ncartao || 'N/A'}` : 'Desconhecido'}
                        <br />
                      </span>
                    );
                  }) : 'Nenhum convênio'}
                </Typography>
              </td>
              <td className="p-4 border-b border-blue-gray-100" style={{ maxWidth: "200px" }}>
                <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
                {`${item.logradouro ? item.logradouro + ', ' : ''}${item.numeral ? item.numeral + ', ' : ''}${item.complemento ? item.complemento + ', ' : ''}${item.bairro ? item.bairro + ', ' : ''}${item.cidade ? item.cidade + ' - ' : ''}${item.estado ? item.estado + ', ' : ''}${item.cep ? item.cep : ''}`}
                </Typography>
              </td>
              <td className="border-b border-blue-gray-100 p-2">
              <div className="flex space-x-2 justify-center">
                {IsAdministradorSupervisor? <Tooltip title="Prontuário Eletrônico" placement="top">
                    <Button
                      placeholder=""
                      color="blue-gray"
                      className="w-8 h-8 flex items-center justify-center p-1 bg-blue-500 hover:bg-blue-700 text-white rounded-full"
                      onClick={() => handleProntuarioButtonClick(item.id_pac)} // Adicione a função para lidar com o clique
                      >
                      <ClipboardIcon className="w-6 h-6" />
                    </Button>
                  </Tooltip>: ''}
                  <Tooltip title="Visualizar">
                    <Button 
                      placeholder=''
                      className="w-8 h-8 flex items-center justify-center p-1 bg-blue-500 hover:bg-blue-700 text-white rounded-full"
                      onClick={() => handleViewButtonClick(item)}
                    >
                      <MagnifyingGlassIcon className="h-5 w-5" />
                    </Button>
                  </Tooltip>
                  {AdministrativoPermissaoPessoa || IsAdministradorSupervisor? <Tooltip title="Atualizar Informações" placement="top">
                    <Typography
                      placeholder=''
                      variant="small"
                      as="button"
                      onClick={() => handleEditButtonClick(item)}
                      color="blue-gray"
                      className="w-8 h-8 flex items-center justify-center p-1 bg-green-500 hover:bg-green-700 text-white rounded-full"
                      >
                      <PencilIcon className="w-6 h-6" />
                    </Typography>
                  </Tooltip>:''}
                  {IsAdministrador === true? <Tooltip title="Deletar" placement="top">
                    <Button
                      placeholder=""
                      color="blue-gray"
                      className="w-8 h-8 flex items-center justify-center p-1 bg-red-500 hover:bg-red-700 text-white rounded-full"
                      onClick={() => handleDeleteButtonClick(item)}
                    >
                      <TrashIcon className="w-6 h-6" />
                    </Button>
                  </Tooltip>:''}
                </div>
              </td>
              </tr>
            ))}
          </tbody>
          {confirmDelete && deleteItem && (
            <div className="fixed inset-0 z-10 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
              <div className="relative w-auto max-w-3xl mx-auto my-6">
                <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                  <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blue-gray-200">
                    <h3 className="text-3xl font-semibold">Confirmar exclusão</h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={cancelDeleteItem}
                    >
                      <span className="text-black h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                    </button>
                  </div>
                  <div className="relative p-6 flex-auto">
                    <p className="my-4 text-blue-gray-500 text-lg leading-relaxed">Tem certeza que deseja excluir o Paciente ID: {deleteItem.id_pac} / Paciente: {deleteItem.nome}?</p>
                  </div>
                  <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blue-gray-200">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={cancelDeleteItem}
                    >
                      Cancelar
                    </button>
                    <button
                      className="bg-red-700 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={confirmDeleteItem}
                    >
                      Confirmar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </table>
      </Card>
      <CardFooter placeholder='' className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Typography placeholder='' variant="small" color="blue-gray" className="font-normal">
        Página <strong className="text-blue-gray-900">{currentPage}</strong> de{" "}
        <strong className="text-blue-gray-900">{totalPages}</strong>
        </Typography>
        <div className="flex gap-2">
        <Button 
          placeholder='' 
          variant="outlined" 
          size="sm"
          onClick={first}
          disabled={currentPage === 1}
          className="p-2"
          >
            Primeira
          </Button>
          <Button 
          placeholder='' 
          variant="outlined" 
          size="sm"
          onClick={prev}
          disabled={currentPage === 1}
          className="p-2"
          >
            Anterior
          </Button>
          <Button 
          placeholder='' 
          variant="outlined" 
          size="sm" 
          onClick={next}
          disabled={currentPage === totalPages}
          className="p-2"
          >
            Próximo
          </Button>
          <Button 
          placeholder='' 
          variant="outlined" 
          size="sm"
          onClick={ult}
          disabled={currentPage === totalPages}
          className="p-2"
          >
            Última
          </Button>
        </div>
      </CardFooter>
      <DetailModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        data={modalData}
      />
    </div>
  );
}
