import React, { useContext, useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/pt-br";
import {
  CursorArrowRaysIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import { Button } from "@material-tailwind/react";
import Modal from "react-modal";
import { GeralContext } from "../../../contexts/Geral/GeralContext";
import { PessoaContext } from "../../../contexts/Pessoa/PessoaContext";
import MessageBox from "../../../components/Utils/MessageBox";
import { AuthContext } from "../../../contexts/Auth/AuthContext";
import CustomCalendar from "../../../components/Utils/CalendarRecepcao";
import CustomCalendarGrupo from "../../../components/Utils/CalendarGrupoRecepcao";
import Legend from "../../../components/Utils/Legend";

const localizer = momentLocalizer(moment);

const customStyles: Modal.Styles = {
  content: {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -20%)",
    backgroundColor: "gray",
    padding: "20px",
    borderRadius: "8px",
    maxWidth: "800", // Ajuste a largura máxima conforme necessário
    width: "50%", // Usando uma porcentagem para a largura
    maxHeight: "90vh", // Ajuste a altura máxima conforme necessário
    overflowY: "auto",
  },
  overlay: {
    zIndex: 999, // Certifique-se de que o modal fique acima de outros elementos
  },
};

interface ConvenioSelecionado {
  codconvenio: string;
  ncartao: string | null;
}

interface ConvenioItem {
  codconvenio: string;
  convenio: string;
}
interface AgendaItem {
  id_agenda: string | null;
  nome_paciente: string;
  id_pac: string;
  codconvenio: string;
  dt_evento: string;
  hr_inicio: string;
  hr_fim: string;
  nome_terapeuta: string;
  id_terap: string;
  paciente: string;
  convenio: string;
  n_sala: string;
  codproc: string;
  atividade: string;
  n_pessoas: string;
  horario: string;
  tmp_atividade: string;
  situacao: string; // Nova propriedade para a situação do evento
  observacao: string;
}

interface Atividade {
  id_terap: string;
  terapeuta: string;
  id_administrativo: string;
  administrativo: string;
  id_ativ: string;
  atividade: string;
  tmp_atividade: string;
}

interface Turnos {
  manha: Atividade;
  tarde: Atividade;
}

interface SalaItem {
  id_sala: number;
  n_sala: string;
  turnos: {
    [key: string]: Turnos;
  };
}

interface PacienteItem {
  id_pac: string;
  nome: string;
  dt_nasc: string;
  genero: string;
  responsavel: string;
  codconvenio: string;
  convenio: string;
  periodo: string;
  dias: number;
  sessoes: number;
  cep: string;
  estado: string;
  cidade: string;
  bairro: string;
  logradouro: string;
  numeral: string;
  complemento: string;
  telefone: string;
  convenios_selecionados: ConvenioSelecionado[]; // Novo campo
}

interface TerapeutaItem {
  id_terap: string;
  id_espec: string;
  especialidade: string;
  nome: string;
  dt_inicio: string;
  dt_fim: string;
  email: string;
  telefone: number;
}

export function AgendaRecepcaoManha() {
  const { agendaRecepcaoActions, salaActions } = useContext(GeralContext);
  const { terapActions, pacActions } = useContext(PessoaContext);
  const [showAddModal, setShowAddModal] = useState(false);
  const [tableAgenda, setTableAgenda] = useState<AgendaItem[]>([]);
  const [tableSala, setTableSala] = useState<SalaItem[]>([]);
  const [tablePac, setTablePac] = useState<PacienteItem[]>([]);
  const [tableTerap, setTableTerap] = useState<TerapeutaItem[]>([]);
  const [eventStartTime, setEventStartTime] = useState<string>("");
  const [eventEndTime, setEventEndTime] = useState<string>("");
  const [selectedPaciente, setSelectedPaciente] = useState("");
  const [selectedSala, setSelectedSala] = useState<string | null>(null);
  const [codProc, setCodproc] = useState<string>("");
  const [atividade, setAtividade] = useState<string>("");
  const [selectedConvenio, setSelectedConvenio] = useState("");
  const { convenioActions } = useContext(GeralContext);
  const [observacao, setObservacao] = useState<string>("");
  const [conveniosDisponiveis, setConveniosDisponiveis] = React.useState<
    ConvenioItem[]
  >([]);
  const [conveniosPaciente, setConveniosPaciente] = React.useState<
    ConvenioItem[]
  >([]);
  const [eventSituation, setEventSituation] = useState("");

  const [tmpAtividade, setTmpAtividade] = useState<string>("");
  const [selectedTerapeuta, setSelectedTerapeuta] = useState("");
  const [message, setMessage] = useState<string>("");
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState(() => {
    const storedDate = localStorage.getItem("selectedDateManha");
    return storedDate ? new Date(storedDate) : new Date();
  });
  const auth = useContext(AuthContext);
  const idUser = auth.user?.id_user;
  const maxTime = new Date(0, 0, 0, 12);

  // Verificar se é manhã ou tarde com base no minTime e maxTime
  const period = maxTime.getHours() <= 12 ? "manha" : "tarde";

  let dayOfWeek = moment(selectedDate).locale("pt").format("dddd");

  // Capitaliza a primeira letra
  dayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);

  // Função para buscar os convênios disponíveis
  useEffect(() => {
    const fetchConvenioData = async () => {
      try {
        const convenioData = await convenioActions.fill();
        setConveniosDisponiveis(convenioData);
      } catch (error) {
        console.error("Erro ao buscar dados dos convenios:", error);
      }
    };
    fetchConvenioData();
  }, [convenioActions]);

  const fetchItemData = async (
    agendaRecepcaoActions: any,
    setTableAgenda: React.Dispatch<React.SetStateAction<AgendaItem[]>>
  ) => {
    let ItemData: AgendaItem[];
    try {
      ItemData = await agendaRecepcaoActions.fill();
      setTableAgenda(ItemData);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  const handleAddEvent = async () => {
    // Verifique se o horário de início está entre 8:00 e 12:00
    const startTime = moment(eventStartTime, "HH:mm");
    const eightAM = moment("08:00", "HH:mm");
    const twelvePM = moment("12:00", "HH:mm");

    if (startTime.isBefore(eightAM) || startTime.isAfter(twelvePM)) {
      // Se o horário de início estiver fora do intervalo permitido, exiba uma mensagem de erro
      setMessage("O horário de início deve estar entre 8:00 e 12:00.");
      setShowMessage(true);
      setShowIcon(true);
      return; // Saia da função sem adicionar o evento
    }

    if (
      (idUser &&
        selectedPaciente &&
        selectedDate &&
        eventStartTime &&
        selectedSala !== null) ||
      (idUser &&
        selectedPaciente &&
        selectedDate &&
        eventStartTime &&
        selectedSala)
    ) {
      try {
        // Calcule o horário de término
        const startDateTime = moment(eventStartTime, "HH:mm");
        const duration = moment.duration(tmpAtividade);
        let endTimeFormatted: string;

        if (eventEndTime) {
          endTimeFormatted = moment(eventEndTime, "HH:mm").format("HH:mm");
        } else {
          const endDateTime = startDateTime.clone().add(duration);
          endTimeFormatted = endDateTime.format("HH:mm");
        }

        // Formate a data para 'YYYY-MM-DD'
        const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
        let ItemAdded;

        if (eventEndTime) {
          ItemAdded = await agendaRecepcaoActions.add({
            id_agenda: null,
            id_pac: selectedPaciente,
            n_sala: selectedSala,
            codconvenio: selectedConvenio,
            codproc: null,
            dt_evento: formattedDate,
            hr_inicio: eventStartTime,
            hr_fim: endTimeFormatted,
            id_terap: selectedTerapeuta,
            situacao: eventSituation,
            id_user: idUser,
            observacao: observacao,
          });
        } else {
          ItemAdded = await agendaRecepcaoActions.add({
            id_agenda: null,
            id_pac: selectedPaciente,
            n_sala: selectedSala,
            codconvenio: selectedConvenio,
            codproc: codProc,
            dt_evento: formattedDate,
            hr_inicio: eventStartTime,
            hr_fim: endTimeFormatted,
            id_terap: selectedTerapeuta,
            situacao: eventSituation,
            id_user: idUser,
            observacao: observacao,
          });
        }

        if (ItemAdded) {
          // Se for bem-sucedido
          setMessage("Dados atualizados com sucesso.");
          fetchItemData(agendaRecepcaoActions, setTableAgenda);
          setShowMessage(true);
          setShowIcon(false);
        } else {
          // Se a API não forneceu mais detalhes
          setMessage("Erro ao processar a solicitação.");
          setShowMessage(true);
          setShowIcon(true);
        }
      } catch (error: any) {
        // Tratamento de exceções inesperadas (AxiosError)
        if (error.response) {
          // Se a resposta do erro contém dados
          const errorMessage =
            error.response.data?.error || "Erro ao processar a solicitação.";
          setMessage(errorMessage);
        } else {
          // Se não há resposta, um erro inesperado ocorreu
          setMessage(
            "Ocorreu um erro inesperado ao tentar atualizar os dados."
          );
        }
        setShowMessage(true);
        setShowIcon(true);
      }
    } else {
      // Mensagem de erro se campos estiverem faltando
      setMessage("Por favor, preencha todos os campos.");
      setShowIcon(true); // Exibe o ícone de erro
    }
  };

  useEffect(() => {
    fetchItemData(agendaRecepcaoActions, setTableAgenda);
  }, [agendaRecepcaoActions, setTableAgenda]);

  useEffect(() => {
    const fetchItemData = async () => {
      let ItemData: AgendaItem[];
      try {
        ItemData = await agendaRecepcaoActions.fill();
        setTableAgenda(ItemData);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };
    fetchItemData();
  }, [agendaRecepcaoActions]);

  useEffect(() => {
    const fetchItemData = async () => {
      let ItemData: SalaItem[];
      try {
        ItemData = await salaActions.fill(period, dayOfWeek);
        setTableSala(ItemData);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };
    fetchItemData();
  }, [salaActions, period, dayOfWeek]);

  useEffect(() => {
    // Encontre o codproc correspondente à sala selecionada
    if (selectedSala !== null) {
      const selectedRoom = tableSala.find(
        (item) => item.n_sala.toString() === selectedSala.toString()
      );
      console.log(tableSala);
      if (selectedRoom) {
        // Atribua os valores de codproc e tmp_atividade com base na sala selecionada
        //        setCodproc(selectedRoom.codproc);
        //       setAtividade(selectedRoom.atividade);
        //      setTmpAtividade(selectedRoom.tmp_atividade);
      } else {
        // Lidere com o caso em que não há correspondência
        //    setCodproc('');
        //    setAtividade('');
        //   setTmpAtividade('');
      }
    }
  }, [selectedSala, tableSala]);

  useEffect(() => {
    const fetchItemData = async () => {
      let ItemData: PacienteItem[];
      try {
        console.log(codProc);
        ItemData = await pacActions.fill(
          undefined,
          codProc,
          true,
          selectedSala
        );
        setTablePac(ItemData);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };
    fetchItemData();
  }, [pacActions, codProc, selectedSala]);

  useEffect(() => {
    const fetchItemData = async () => {
      let ItemData: TerapeutaItem[];
      try {
        ItemData = await terapActions.fill();
        setTableTerap(ItemData);
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };
    fetchItemData();
  }, [terapActions]);

  const handleAddButtonClick = () => {
    setShowAddModal(true);
  };

  const handleCloseMessage = () => {
    setShowAddModal(false); // Feche o modal após adicionar o evento com sucesso
    setShowMessage(false);
  };

  const handleCloseModal = () => {
    // Limpar todos os campos do formulário
    setSelectedPaciente("");
    setSelectedSala(null);
    setSelectedTerapeuta("");
    setEventStartTime("");
    setEventEndTime("");
    setCodproc("");
    setAtividade("");
    setTmpAtividade("");

    // Fechar o modal
    setShowAddModal(false);
  };

  // Atualiza a data selecionada no local storage sempre que ela mudar
  useEffect(() => {
    localStorage.setItem("selectedDateManha", selectedDate.toISOString());
    localStorage.setItem("dataatual", selectedDate.toISOString());
    
  }, [selectedDate]);

  function handleSalaChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const selectedSala = e.target.value;
    setSelectedSala(selectedSala);

    if (selectedSala && selectedDate) {
      const dayOfWeek = getDayOfWeek(selectedDate); // Obter o dia da semana baseado na data selecionada
      const sala = tableSala.find((sala) => sala.n_sala === selectedSala);

      if (sala && sala.turnos[dayOfWeek]) {
        const turno = sala.turnos[dayOfWeek];

        // Preenche os campos com os dados obtidos do turno
        if (turno) {
          setSelectedTerapeuta(turno.manha.id_terap || "");
          setAtividade(turno.manha.atividade || "");
          setTmpAtividade(turno.manha.tmp_atividade || "");
        }
      } else {
        // Limpa os campos se não houver dados para o dia da semana
        setSelectedTerapeuta("");
        setAtividade("");
        setTmpAtividade("");
      }
    }
  }

  // Função para obter o dia da semana a partir da data selecionada
  function getDayOfWeek(date: Date): string {
    const daysOfWeek = [
      "Domingo",
      "Segunda-Feira",
      "Terça-Feira",
      "Quarta-Feira",
      "Quinta-Feira",
      "Sexta-Feira",
      "Sábado",
    ];
    return daysOfWeek[date.getDay()];
  }

  // Função para retroceder a data
  const handlePreviousDate = () => {
    setSelectedDate((prevDate) => {
      const newDate = moment(prevDate).subtract(1, "day").toDate();
      return newDate;
    });
  };

  // Função para avançar a data
  const handleNextDate = () => {
    setSelectedDate((prevDate) => {
      const newDate = moment(prevDate).add(1, "day").toDate();
      return newDate;
    });
  };

  const handlePacienteChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const pacienteId = e.target.value;
    setSelectedPaciente(pacienteId); // Mantém o id como string

    if (pacienteId) {
      // Encontrar o paciente selecionado
      const paciente = tablePac.find((p) => p.id_pac.toString() === pacienteId);

      if (paciente) {
        // Filtrar os convênios disponíveis baseados nos convênios selecionados do paciente
        const conveniosSelecionados: ConvenioSelecionado[] =
          paciente.convenios_selecionados || [];

        const conveniosFiltrados = conveniosDisponiveis.filter((convenio) =>
          conveniosSelecionados.some(
            (c: ConvenioSelecionado) => c.codconvenio === convenio.codconvenio
          )
        );

        setConveniosPaciente(conveniosFiltrados);

        // Verificar se há apenas um convênio e selecioná-lo automaticamente
        if (conveniosFiltrados.length === 1) {
          setSelectedConvenio(conveniosFiltrados[0].codconvenio);
        }
      } else {
        setConveniosPaciente([]);
        setSelectedConvenio(""); // Reseta o convênio selecionado se não houver paciente
      }
    }
  };

  const handleReplicarButtonClick = () => {
    const nextWeekDate = moment(selectedDate)
      .add(1, "week")
      .format("dddd, D [de] MMMM [de] YYYY");
    const confirmReplicate = window.confirm(
      `A agenda será replicada para a próxima semana, ${nextWeekDate}. Deseja confirmar?`
    );

    if (confirmReplicate) {
      replicar();
    }
  };
  const replicar = async () => {
    try {
      // Data atual (data selecionada)
      const dataAtual = moment(selectedDate).format("YYYY-MM-DD");

      // Data da próxima semana
      const nextWeek = moment(selectedDate).add(7, "days").format("YYYY-MM-DD");

      // Fazendo a requisição para replicar
      const response = await agendaRecepcaoActions.replicar(
        dataAtual,
        nextWeek,
        String(idUser)
      );

      console.log(
        "Agenda replicada com sucesso para a data:",
        nextWeek,
        response
      );
    } catch (error) {
      console.error("Erro ao replicar a agenda:", error);
    }
  };
  return (
    <div className="w-full m-0 p-0" style={{ margin: 0, padding: 0 }}>
      <div className="text-center mb-4">
        <h2 className="font-bold">
          {" "}
          AGENDA RECEPÇÃO{" "}
          {moment(selectedDate).format("dddd, D [de] MMMM [de] YYYY")}
        </h2>
      </div>

      {/* Controle de data */}
      <div className="flex items-center">
        <button
          onClick={handlePreviousDate}
          className="px-4 py-2 bg-blue-500 text-white rounded-l-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Anterior
        </button>
        <input
          type="date"
          value={moment(selectedDate).format("YYYY-MM-DD")}
          onChange={(e) => {
            const selectedDateString = e.target.value;
            const selectedDateParts = selectedDateString.split("-");
            const year = parseInt(selectedDateParts[0]);
            const month = parseInt(selectedDateParts[1]) - 1; // Mês base zero
            const day = parseInt(selectedDateParts[2]);
            const newDate = new Date(year, month, day);
            setSelectedDate(newDate);
          }}
          className="px-4 py-2 mx-2 text-center text-sm text-gray-700 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
        />
        <button
          onClick={handleNextDate}
          className="px-4 py-2 bg-blue-500 text-white rounded-r-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Seguinte
        </button>
      </div>

      <div className="w-full m-0 p-0" style={{ margin: 0, padding: 0 }}>
        {/* Calendário normal com legenda abaixo */}
        <div className="w-full m-0 p-0" style={{ margin: 0, padding: 0 }}>
          <CustomCalendar
            tableAgenda={tableAgenda}
            tablePac={tablePac}
            tableSala={tableSala}
            tableTerap={tableTerap}
            localizer={localizer}
            minTime={new Date(0, 0, 0, 8)}
            maxTime={new Date(maxTime)}
            selectedDate={selectedDate}
          />
        </div>

        {/* Texto descritivo sobre o calendário do grupo */}
        <div className="w-full m-0 p-0" style={{ margin: 0, padding: 0 }}>
          <p
            style={{ fontSize: "1.2em", fontWeight: "bold", margin: "10px 0" }}
          >
            Atividades em Grupo
          </p>
        </div>

        {/* Calendário do grupo ao lado direito */}
        <div className="w-full m-0 p-0" style={{ margin: 0, padding: 0 }}>
          <CustomCalendarGrupo
            tableAgenda={tableAgenda}
            tablePac={tablePac}
            tableSala={tableSala}
            tableTerap={tableTerap}
            localizer={localizer}
            minTime={new Date(0, 0, 0, 8)}
            maxTime={new Date(maxTime)}
            selectedDate={selectedDate}
          />
        </div>
      </div>

      <Modal
        isOpen={showAddModal}
        onRequestClose={handleCloseModal}
        style={customStyles}
      >
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          {showMessage && (
            <MessageBox
              message={message}
              onClose={() => {
                setShowIcon(false);
                handleCloseMessage();
              }}
              icon={
                showIcon ? (
                  <ExclamationCircleIcon className="w-10 h-10 mx-auto text-red-500" />
                ) : null
              }
            />
          )}
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Agendamento -{" "}
              {moment(selectedDate).format("dddd, D [de] MMMM [de] YYYY")}
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={handleCloseModal}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <form className="p-4 md:p-5">
            <div className="grid gap-4 mb-4 grid-cols-2">
              {/* Select da Sala */}
              <div>
                <label
                  htmlFor="sala"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Sala
                </label>
                <select
                  id="sala"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  value={selectedSala ?? ""}
                  onChange={handleSalaChange}
                  //onChange={(e) => setSelectedSala(e.target.value)}
                >
                  <option value="">Selecione a Sala</option>
                  {tableSala.map((sala) => (
                    <option key={sala.n_sala} value={sala.n_sala}>
                      {sala.n_sala}
                    </option>
                  ))}
                </select>
              </div>
              {/* Select do Paciente */}
              <div>
                <label
                  htmlFor="paciente"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Paciente
                </label>
                <select
                  id="paciente"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  value={selectedPaciente}
                  onChange={(e) => handlePacienteChange(e)}
                >
                  <option value="">Selecione o Paciente</option>
                  {tablePac
                    .sort((a, b) =>
                      a.nome.localeCompare(b.nome, "pt-BR", {
                        ignorePunctuation: true,
                      })
                    )
                    .map((paciente) => (
                      <option key={paciente.id_pac} value={paciente.id_pac}>
                        {paciente.nome}
                      </option>
                    ))}
                </select>
              </div>
              {/* Select do Plano de Saúde */}
              <div>
                <label
                  htmlFor="convenio"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Plano de Saúde
                </label>
                <select
                  id="convenio"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  value={selectedConvenio}
                  onChange={(e) => setSelectedConvenio(e.target.value)}
                >
                  <option value="">Selecione o Plano de Saúde</option>
                  {conveniosPaciente.map((convenio) => (
                    <option
                      key={convenio.codconvenio}
                      value={convenio.codconvenio}
                    >
                      {convenio.convenio}
                    </option>
                  ))}
                </select>
              </div>

              {/* Select do Terapeuta */}
              <div>
                <label
                  htmlFor="terapeuta"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Terapeuta
                </label>
                <select
                  id="terapeuta"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  value={selectedTerapeuta}
                  onChange={(e) => setSelectedTerapeuta(e.target.value)}
                  disabled
                >
                  <option value="">Selecione o Terapeuta</option>
                  {tableTerap.map((terapeuta) => (
                    <option key={terapeuta.id_terap} value={terapeuta.id_terap}>
                      {terapeuta.nome}
                    </option>
                  ))}
                </select>
              </div>
              {/* Campo de Hora Inicial */}
              <div>
                <label
                  htmlFor="start-time"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Hora Inicial
                </label>
                <input
                  type="time"
                  id="start-time"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  value={eventStartTime}
                  onChange={(e) => setEventStartTime(e.target.value)}
                />
              </div>
              {selectedSala === "0" ? (
                <div>
                  <label
                    htmlFor="start-end"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Hora Final
                  </label>
                  <input
                    type="time"
                    id="start-end"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    value={eventEndTime}
                    onChange={(e) => setEventEndTime(e.target.value)}
                  />
                </div>
              ) : (
                ""
              )}
              {selectedSala !== "0" ? (
                <div className="mt-2">
                  <span className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                    Tempo de Atividade: {tmpAtividade}
                  </span>
                  <span className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Atividade: {atividade}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-span-2">
              <label
                htmlFor="observacao"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Observação
              </label>
              <input
                type="text"
                id="observacao"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                value={observacao}
                onChange={(e) => setObservacao(e.target.value)}
              />
            </div>
            <div className="col-span-2">
              <label
                htmlFor="situation"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Situação
              </label>
              <select
                id="situation"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                value={eventSituation}
                onChange={(e) => setEventSituation(e.target.value)}
              >
                {/* Opções para as situações */}
                <option value="Presente">Presente</option>
                <option value="Falta">Falta</option>
                <option value="Encaixe">Encaixe</option>
                <option value="Desmarcado pela clínica">
                  Desmarcado pela clínica
                </option>
              </select>
            </div>
            <div className="flex justify-end gap-4 mt-4">
              <button
                type="button"
                onClick={handleAddEvent}
                className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Concluir
              </button>
            </div>{" "}
          </form>
        </div>
      </Modal>
    </div>
  );
}
