import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import logo from '../../images/logo/logo.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Login = () => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        const checkAuthentication = async () => {
            if (auth.user) {
                navigate('/bemvindo');
            }
        };

        checkAuthentication();
    }, [auth.user, navigate]);

    const handleEmailInput = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }

    const handlePasswordInput = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }

    const handleLogin = async () => {
        if (email && password) {
            try {
                const isLogged = await auth.signin(email, password);
                if (isLogged) {
                    navigate('/bemvindo');
                } else {
                    toast.error("Não foi possível fazer o login, verifique se seu e-mail e senha estão corretos.");
                }
            } catch (error) {
                toast.error("Erro inesperado ao tentar fazer login. Por favor, tente novamente.");
            }
        } else {
            toast.warning("Por favor, preencha todos os campos.");
        }
    }

    return (
        <div>
            <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <img className="mx-auto h-30 w-auto" src={logo} alt="Clínica Praticar" />
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Endereço de e-mail</label>
                        <div className="mt-2 mb-3">
                            <input id="email" name="email" type="email" autoComplete="email" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" onChange={handleEmailInput} />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Senha</label>
                        <div className="mt-2 mb-5">
                            <input id="password" name="password" type="password" autoComplete="current-password" required className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" onChange={handlePasswordInput} />
                        </div>
                    </div>

                    <div>
                        <button type="submit" className="m-3 flex w-full justify-center rounded-md bg-green-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={handleLogin}>Entrar</button>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}
