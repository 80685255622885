import * as pdfMake from "pdfmake/build/pdfmake";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import logo from "../../images/logo/logo.png";

// Importe os arquivos de fonte do pdfmake
import pdfFonts from "pdfmake/build/vfs_fonts";

// Crie um novo objeto vfs com os arquivos de fonte
const newVfs = pdfFonts.pdfMake.vfs;

// Atribua o novo objeto vfs à propriedade pdfMake.vfs
(pdfMake as any).vfs = newVfs;

interface Paciente {
    id_seq: string;
    nome: string;
    dt_nasc: string;
    genero: string;
    logradouro: string;
    numeral: string;
    bairro: string;
    cidade: string;
    estado: string;
    cep: string;
    convenio: string;
    n_cartao: string;
    sessoes: number;
    periodo: string;
    dias?: number;
    med_resp: string;
    crm: string;
    tel_med: string;
    cid_geral: string;
    diag_geral: string;
    cid_fisio: string;
    diag_fisio: string;
    cid_fono: string;
    diag_fono: string;
    cid_psico: string;
    diag_psico: string;
    responsaveis?: Responsavel[];
    evolucao?: EvolucaoData[];
}

interface EvolucaoData {
    id_pac: string;
    historico_procedimentos:  string;
    id_terap:  string;
    nome_terap: string;
    dt_insert:  string;
}

interface Responsavel {
    nome: string;
    filiacao: string;
    telefone: string;
}

export class Evolucao {
    private idPaciente: string;
    private pacActions: any;
    private pacienteData: Paciente | null;
    private idUser: string;
    private nome: string | undefined;
    private ocupacao: string;

    constructor(idPaciente: string, pacActions: any, idUser: string, nome: string | undefined, ocupacao: string) {
        this.idPaciente = idPaciente;
        this.pacActions = pacActions;
        this.pacienteData = null;
        this.idUser = idUser;
        this.nome = nome;
        this.ocupacao = ocupacao;
    }

    public async PreparaDocumento(): Promise<any> {
        await this.buscarDadosPaciente();
        const docDefinition = this.CriaCorpoDocumento();
        const documento = this.GerarDocumento(docDefinition);
        return documento;
    }
    
    private async buscarDadosPaciente(): Promise<void> {
        try {
            const pacData: Paciente[] = await this.pacActions.fill(this.idPaciente);
            if (pacData && pacData.length > 0) {
                this.pacienteData = pacData[0]; // Atribui o primeiro objeto de paciente à variável pacienteData
                console.log(this.pacienteData);
            } else {
                console.error("Nenhum dado de paciente encontrado.");
            }
        } catch (error) {
            console.error("Erro ao buscar dados do paciente:", error);
        }
    }

    private CriaCorpoDocumento(): TDocumentDefinitions {
        if (!this.pacienteData) {
            console.error("Dados do paciente não encontrados.");
            return { content: [] };
        }

        const evolucao = this.pacienteData.evolucao || [];
        console.log(this.pacienteData.evolucao);

        let content: any[] = [
            // Coluna 1 - Logo e Informações
            {
                columns: [
                    {
                        image: logo,
                        width: 100,
                        margin: [0, 0, 0, 10], // Margin: top, right, bottom, left
                    },
                    {
                        stack: [
                            { text: [{ text: "Nº do Prontuário / Paciente: ", bold: true, italics: true }, { text: (this.pacienteData?.id_seq || ""), fontSize: 10 }], margin: [0, 0, 0, 5] },
                            { text: [{ text: "Data e Hora: ", bold: true, italics: true }, { text: this.getCurrentDateTime(), fontSize: 10 }], margin: [0, 0, 0, 5] },
                            { text: [{ text: "Impresso por: ", bold: true, italics: true  }, { text: (this.nome ? ':' : '')  + (this.nome ?? '') + (this.ocupacao ? ':' : '') + (this.ocupacao ?? ''), fontSize: 10 }], margin: [0, 0, 0, 5] },                        
                        ],
                        alignment: 'right',
                    },
                ],
            },
            { text: [{ text: "Paciente: ", bold: true, italics: true }, { text: (this.pacienteData?.nome || ""), fontSize: 10 }], margin: [10, 0, 0, 5] },
            // Incluindo histórico de procedimentos
            { text: "Histórico:", bold: true, italics: true, margin: [10, 25, 0, 5] },
            { canvas: [{ type: 'line', x1: 0, y1: 0, x2: 520, y2: 0, lineWidth: 1, lineColor: '#000000' }], margin: [10, 0, 0, 5] },
        ];

        evolucao.forEach((item, index) => {
            if (item.historico_procedimentos !== null && item.historico_procedimentos !== undefined) {
                content.push(
                    { text: [{ text: "Data / Terapeuta: ", bold: true, italics: true }, { text: `${new Date(item.dt_insert).toLocaleString()} | ${item.id_terap === '2115711416861374'?'Administrador Clínica Praticar':item.nome_terap}`, fontSize: 10 }], margin: [10, 5, 0, 5] },
                    { text: `${item.historico_procedimentos}`, fontSize: 10, margin: [10, 5, 0, 5] }
                );
            }
        });

        const docDefinition: TDocumentDefinitions = {
            content,
            footer: function (currentPage: number, pageCount: number) {
                return [
                    { text: `Página ${currentPage} de ${pageCount}`, alignment: "center", fontSize: 9 },
                    { text: "© Clínica Praticar - Amor - Saúde - Vida", alignment: "center", fontSize: 8 },
                ];
            },
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10],
                },
            },
        };

        return docDefinition;
    }

    private GerarDocumento(docDefinition: TDocumentDefinitions): any {
        pdfMake.createPdf(docDefinition).open();
    }

    private calculateAgeInDays(birthdate: string): number {
        const today = new Date();
        const birthDate = new Date(birthdate);
        const diffTime = Math.abs(today.getTime() - birthDate.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    }

    private calculateAge(birthdate: string): { years: number; months: number; days: number } {
        const ageInDays = this.calculateAgeInDays(birthdate);
        let years = Math.floor(ageInDays / 365);
        let months = Math.floor((ageInDays % 365) / 30);
        let days = (ageInDays % 365) % 30;

        return { years, months, days };
    }

    private getCurrentDateTime() {
        const currentDateTime = new Date();
        const day = currentDateTime.getDate();
        const month = currentDateTime.getMonth() + 1;
        const year = currentDateTime.getFullYear();
        const hour = currentDateTime.getHours().toString().padStart(2, '0');
        const minute = currentDateTime.getMinutes().toString().padStart(2, '0');
        const second = currentDateTime.getSeconds().toString().padStart(2, '0');
        return `${day}/${month}/${year} ${hour}:${minute}:${second}`;
    }
}
