import { createContext } from 'react';
import { SalaParams } from '../../types/Sala';
import { EspecParams } from '../../types/Espec';
import { ConvenioParams } from '../../types/Convenio';
import { ProcedimentoParams } from '../../types/Procedimento';
import { AgendaParams } from '../../types/Agenda';
import { CidParams } from '../../types/Cid';
import { AtividadeParams } from '../../types/Atividade';


type GenericParams = SalaParams | EspecParams | ConvenioParams | ProcedimentoParams | AgendaParams | CidParams | AtividadeParams;

type ActionFunction = (params: GenericParams) => Promise<boolean>;

interface Actions {
    add: ActionFunction;
    edit: ActionFunction;
    delete: (id: string, id_user: string) => Promise<boolean>;
    replicar: (dataAtual: string,nextWeek: string, id_user: string) => Promise<boolean>;

    fill: (period?: string, dayOfWeek?: string) => Promise<any>;
}

export type GeralContextType = {
    salaActions: Actions;
    procedimentoActions: Actions;
    especActions: Actions;
    atividadeActions: Actions;
    convenioActions: Actions;
    agendaActions: Actions;
    agendaRecepcaoActions: Actions;
    cidActions: Actions;
}

export const GeralContext = createContext<GeralContextType>(null!);
