import { createContext } from 'react';
import { PacParams } from '../../types/Paciente';
import { TerapeutaParams } from '../../types/Terapeuta';
import { MonitorParams } from '../../types/Monitor';
import { HistoricoMedParams } from '../../types/HistoricoMed';
import { AdministrativoParams } from '../../types/Administrativo';

type GenericParams = PacParams | TerapeutaParams | MonitorParams | HistoricoMedParams | AdministrativoParams

type ActionFunction = (params: GenericParams) => Promise<boolean>;

interface Actions {
    add: ActionFunction;
    edit: ActionFunction;
    delete: (id: string, id_user: string, id_hash?: string) => Promise<boolean>;
    fill: (id?: string, codProc?: string, flagAgenda?: boolean, salaRef?: string | null) => Promise<any>;
}

export type PessoaContextType = {
    pacActions: Actions;
    terapActions: Actions;
    monitorActions: Actions;
    administrativoActions: Actions;
    historicomedActions: Actions;
}

export const PessoaContext = createContext<PessoaContextType>(null!);
