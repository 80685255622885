import React from 'react';

const situationColors: { [key: string]: string } = {
  'Agendado': '#FAFAD2', // GELO
  'Presente': '#4CAF50',
  'Falta': '#FF5252',
  'Sai após este atendimento': '#FFEB3B',
  'Avaliação': '#FFA726',
  'Devolutiva': '#D3D3D3',
  'Troca de horário ou terapeuta': '#0D47A1',
  'Encaixe': '#BA68C8',
  'Desmarcado pela clínica': '#FF4081',
  'Controle Interno': '#64B5F6',
};

const Legend: React.FC = () => {
  const legendItems = Object.entries(situationColors);

  return (
    <div className="legend-container">
      {legendItems.map(([situation, color]) => (
        <div className="legend-item" key={situation}>
          <span className="color-box" style={{ backgroundColor: color }}></span>
          {situation}
        </div>
      ))}
      <style>{`
        .legend-container {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          grid-gap: 5px; /* Reduz o espaçamento entre os itens */
          max-width: 100%;
          margin: auto; /* Centraliza a legenda horizontalmente */
        }
        .legend-item {
          display: flex;
          align-items: center;
          font-size: 0.8rem; /* Reduz o tamanho da fonte */
          padding: 2px 4px; /* Reduz o padding interno */
        }
        .color-box {
          width: 15px; /* Reduz o tamanho da caixa colorida */
          height: 15px;
          display: inline-block;
          margin-right: 5px;
        }
      `}</style>
    </div>
  );
};

export default Legend;
