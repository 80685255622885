import { useContext, useEffect, useState } from "react";
import { GeralContext } from "../../../../contexts/Geral/GeralContext";
import { Link, useLocation } from "react-router-dom";
import MessageBox from "../../../../components/Utils/MessageBox";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { AuthContext } from "../../../../contexts/Auth/AuthContext";

interface ProcItem {
  codproc: string;
  procedimento: string;
}

export const EditaConvenio = () => {
  const { convenioActions, procedimentoActions } = useContext(GeralContext);
  const [codConvenio, setCodConvenio] = useState<string>('');
  const [convenio, setConvenio] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [showIcon, setShowIcon] = useState<boolean>(false);
  const location = useLocation();
  const selectedItem = location.state?.selectedItem;
  const [procedimentos, setProcedimentos] = useState<ProcItem[]>([]);
  const [filteredProcedimentos, setFilteredProcedimentos] = useState<ProcItem[]>([]);
  const [selectedProcs, setSelectedProcs] = useState<{ name: string, codproc: string, value: string }[]>([]);
  const [situacao, setSituacao] = useState<number>(
    selectedItem.situacao ? parseInt(selectedItem.situacao) : 0
  );
  const auth = useContext(AuthContext);
  const idUser = auth.user?.id_user;
  const IsAdministrador = !!(auth.user?.ocupacao === 'Administrador' || auth.user?.admin);
  const IsAdministradorSupervisor = !!(auth.user?.ocupacao === 'Administrador' || auth.user?.admin || auth.user?.supervisor);
  const IsTerapeuta = !!(auth.user?.ocupacao === 'Terapeuta');
  const IsMonitor = !!(auth.user?.ocupacao === 'Monitor');
  const IsAdministrativo = !!(auth.user?.ocupacao === 'Administrativo');

  useEffect(() => {
    if (selectedItem.codconvenio !== undefined) {
      setCodConvenio(selectedItem.codconvenio);
    }
    if (selectedItem.convenio !== undefined) {
      setConvenio(selectedItem.convenio);
    }
    if (selectedItem.situacao !== undefined) {
      setSituacao(parseInt(selectedItem.situacao));
    }
    if (selectedItem.procedimentos) {
      const formattedProcs = selectedItem.procedimentos.map((proc: { name: string; codproc: string; value: number }) => ({
        name: proc.name,
        codproc: proc.codproc,
        value: proc.value.toString() // Convertendo para string para manter a consistência
      }));
      setSelectedProcs(formattedProcs);
    }
  }, [selectedItem]);

  const handleToggleProc = (procCod: string) => {
    const index = selectedProcs.findIndex(proc => proc.codproc === procCod);
    if (index !== -1) {
      // Procedimento encontrado, remova-o
      const updatedProcs = [...selectedProcs];
      updatedProcs.splice(index, 1);
      setSelectedProcs(updatedProcs);
    } else {
      // Procedimento não encontrado, adicione-o
      const procToAdd = procedimentos.find(proc => proc.codproc === procCod);
      if (procToAdd) {
        // Adicione o novo procedimento
        setSelectedProcs(prev => [
          ...prev,
          { name: procToAdd.procedimento, codproc: procToAdd.codproc, value: '' }
        ]);
      }
    }
  };

  const toggleAllProcedures = () => {
    if (allProceduresSelected) {
      setSelectedProcs([]);
    } else {
      setSelectedProcs(procedimentos.map(proc => ({
        ...proc,
        value: '',
        name: proc.procedimento
      })));
    }
  };
  
  const allProceduresSelected = selectedProcs.length === procedimentos.length;

  const handleSubmit = async () => {
    if (codConvenio && convenio && situacao !== null && selectedProcs.length > 0 && idUser) {
      // Verifica se todos os campos value não estão vazios
      const allFieldsFilled = selectedProcs.every(proc => proc.value);
      if (allFieldsFilled) {
        // Converter os valores para números antes de enviar para o backend
        const procsToSend = selectedProcs.map(proc => ({
          ...proc,
          value: parseFloat(proc.value),
          date: new Date().toISOString().split('T')[0] // Adicionando a data atual
        }));
        const ItemAdded = await convenioActions.edit({ codconvenio: codConvenio, convenio: convenio, situacao: situacao, procedimentos: procsToSend, id_user: idUser });
        if (ItemAdded) {
          setMessage('Dados atualizados com sucesso');
        } else {
          setMessage('Não foi possível atualizar os dados.');
          setShowIcon(true);
        }
      } else {
        setMessage("Por favor, preencha todos os campos de valor.");
        setShowIcon(true);
      }
    } else {
      setMessage("Por favor, preencha todos os campos.");
      setShowIcon(true);
    }
    setShowMessage(true);
  }

  useEffect(() => {
    const fetchProcData = async () => {
      try {
        const procedimentoData = await procedimentoActions.fill();
        setProcedimentos(procedimentoData);
        setFilteredProcedimentos(procedimentoData);
      } catch (error) {
        console.error("Erro ao buscar dados dos convenios:", error);
      }
    };
    fetchProcData();
  }, [procedimentoActions]);

  const handleCloseMessage = () => {
    setShowMessage(false);
    if (!showIcon) {
      window.location.href = '/convenio'; // Redireciona apenas se não houver ícone de erro
    }
  };

  // Função para aceitar apenas números no campo codConvenio
  const handleCodConvenioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Remover caracteres não numéricos
    const newValue = value.replace(/\D/g, '');
    setCodConvenio(newValue);
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    const filtered = procedimentos.filter(proc => 
      proc.procedimento.toLowerCase().includes(value) || proc.codproc.toLowerCase().includes(value)
    );
    setFilteredProcedimentos(filtered);
  };

  return (
    <div className="min-h-screen flex items-center justify-center pt-12">
      {showMessage && <MessageBox message={message}
        onClose={() => {
          setShowIcon(false);
          handleCloseMessage();
        }}
        icon={showIcon ?
          <ExclamationCircleIcon className="w-10 h-10 mx-auto text-red-500" /> : null}
      />}
      <form className="w-full max-w-md">
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-3xl font-bold leading-8 text-gray-900 border-b-4 border-indigo-500 pb-2">Editar Convênio</h2>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <label htmlFor="convenio" className="block text-sm font-medium leading-6 text-gray-900">Convênio</label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="convenio"
                    id="convenio"
                    autoComplete="given-name"
                    value={convenio}
                    className="block w-full rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setConvenio(e.target.value)}
                    required
                    disabled
                  />
                </div>
              </div>
              <div className="col-span-full">
                <label htmlFor="situacao" className="block text-sm font-medium leading-6 text-gray-900">Situação</label>
                <div className="mt-2">
                  <select
                    name="situacao"
                    id="situacao"
                    autoComplete="situacao"
                    value={situacao}
                    onChange={(e) => {
                      setSituacao(parseInt(e.target.value));
                    }}
                    className="block w-full rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    required
                  >
                    <option value="1">Ativo</option>
                    <option value="0">Inativo</option>
                  </select>
                </div>
              </div>
              <div className="col-span-full">
                <label htmlFor="filter" className="block text-sm font-medium leading-6 text-gray-900">Filtrar Procedimentos</label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="filter"
                    className="block w-full rounded-md border-0 py-1.5 text-center text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={handleFilterChange}
                  />
                </div>
              </div>
              <div className="col-span-full">
                <button
                  type="button"
                  className="text-indigo-600 hover:text-indigo-900"
                  onClick={toggleAllProcedures}
                  style={{ background: 'none', border: 'none', padding: 0, fontSize: 'inherit', textDecoration: 'underline', cursor: 'pointer' }}
                >
                  {allProceduresSelected ? 'Nenhum' : 'Selecionar Todos'}
                </button>
              </div>
              <div className="col-span-full">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Procedimentos
                </label>
                <div className="mt-2">
                  <div className="space-y-4">
                    {filteredProcedimentos.map((proc, index) => (
                      <div key={index} className="flex items-center gap-2 text-xs">
                        <input
                          type="checkbox"
                          checked={selectedProcs.some(selectedProc => selectedProc.codproc === proc.codproc)}
                          onChange={() => handleToggleProc(proc.codproc)}
                        />
                        {proc.codproc} - {proc.procedimento}
                        {selectedProcs.some(selectedProc => selectedProc.codproc === proc.codproc) && (
                          <div className="flex gap-2 mt-2 text-xs flex-1 justify-end">
                            R$
                            <input
                              type="text" // Alterado de 'number' para 'text'
                              className="text-center w-28 bg-gray-100"
                              value={selectedProcs.find(selectedProc => selectedProc.codproc === proc.codproc)?.value || ''}
                              onChange={(e) => {
                                let value = e.target.value;
                                // Substitui vírgulas por pontos
                                value = value.replace(/,/g, '.');
                                // Remove caracteres não numéricos exceto pontos
                                value = value.replace(/[^\d.]/g, '');
                                // Remove zeros à esquerda, exceto se for o único caractere ou seguido por ponto
                                if (value.length > 1 && value[0] === '0' && value[1] !== '.') {
                                  value = value.substring(1);
                                }
                                // Verifica se já existe um ponto e evita múltiplos
                                const decimalCount = (value.match(/\./g) || []).length;
                                if (decimalCount > 1) {
                                  value = value.substring(0, value.lastIndexOf('.'));
                                }
                                const newProcs = [...selectedProcs];
                                const selectedIndex = newProcs.findIndex(selectedProc => selectedProc.codproc === proc.codproc);
                                if (selectedIndex !== -1) {
                                  newProcs[selectedIndex].value = value;
                                  setSelectedProcs(newProcs);
                                }
                              }}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-end gap-x-6 pb-12">
          <Link to="/convenio" className="text-indigo-600 hover:text-indigo-900">Voltar</Link>
          <button
            type="button"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleSubmit}
          >
            Salvar
          </button>
        </div>
      </form>
    </div>
  );
}
