import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import logo from '../../images/logo/logo.png';


export const Welcome = () => {
    const auth = useContext(AuthContext);

    const IsAdministrativo = !!(auth.user?.ocupacao === 'Administrativo');
    const AdministrativoPermissaoAgenda = (auth.user?.permissao ?? 0) >= 2;

    const navigate = useNavigate();

    const handleStartAgendaRecepcao = () => {
        navigate('/agendarecepcao');
    };

    const handleStartAgendamentos = () => {
        navigate('/agenda');
    };

    return (
        <div className="flex min-h-full flex-col justify-center items-center px-6 py-12 bg-blue-50 lg:px-8">
            <div className="text-center">
                <img className="mx-auto h-30 w-auto mb-6" src={logo} alt="Clínica Praticar" />
                <h1 className="text-3xl font-bold text-gray-900 mb-4">Bem-vindo ao Sistema de Agendamento</h1>
                <p className="text-gray-700 text-lg mb-8">
                    Facilite seu atendimento e acompanhe seus agendamentos de forma rápida e prática.
                </p>
                <button 
                    onClick={handleStartAgendaRecepcao} 
                    className="mt-3 w-full max-w-sm px-6 py-2 rounded-md bg-green-700 text-white font-semibold hover:bg-indigo-500"
                >
                    Ir para Agenda Recepção
                </button>
                {(AdministrativoPermissaoAgenda && IsAdministrativo) || !IsAdministrativo? 
                <button 
                    onClick={handleStartAgendamentos} 
                    className="mt-3 w-full max-w-sm px-6 py-2 rounded-md bg-blue-700 text-white font-semibold hover:bg-indigo-500"
                >
                    Ir para Agendamentos
                </button>:''}
            </div>
        </div>
    );
}
